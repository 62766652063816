@import '../../variables';

.hero-wrapper.chickn-dipn {
  background-image: url('../../assets/img/offers/chickn-dipn/top-image.jpg');
  height: 55rem;
  @include align-items(center);
  @include justify-content(center);

  h1 {
    width: 80%;
  }

  .logo {
    position: absolute;
    left: 4%;
    top: 6%;
    width: 10rem;
  }
}

.body-wrapper.chickn-dipn {
  p {
    font-size: 1.8rem;
    line-height: 1.3;
  }

  .descr {
    width: 80%;

    &.short {
      width: 60%;
    }
  }

  .product-intro {
    max-width: 110rem;
    text-align: left;
    width: 100%;
    @include flexbox;
    @include justify-content(center);

    p {
      width: 90%;
    }

    img {
      height: 50rem;
      margin-top: -13rem;
      width: auto;
      z-index: 1;
    }

    .button-group {
      @include justify-content(flex-start);
    }
  }

  .feature-wrapper .feature-block {
    margin-top: 1rem;
  }

  .recipe-wrapper {
    max-width: 115rem;
    width: 100%;
    @include flexbox;
    @include justify-content(center);
    @include flex-wrap(wrap);

    .recipe-item {
      position: relative;
      width: 50%;

      span {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.3;
        left: 6rem;
        position: absolute;
        top: 0;
        text-align: left;
        width: 12rem;
      }

      img {
        width: auto;
      }

      &:nth-child(1) {
        span {
          width: 15rem;
          top: 1rem;
        }

        img {
          height: 37rem;
        }
      }

      &:nth-child(2) {
        margin-left: -7rem;
        margin-top: 7rem;

        span {
          top: 3rem;
          left: 11rem;
        }

        img {
          height: 43rem;
        }
      }

      &:nth-child(3) {
        margin-left: -30rem;
        margin-top: -20rem;

        span {
          top: 34rem;
          left: 15rem;
        }

        img {
          height: 55rem;
        }
      }
    }
  }

  .product-wrapper {
    @include flexbox;
    @include justify-content(center);
    @include flex-wrap(wrap);

    img {
      height: 100%;
      max-width: 30rem;
      width: 100%;
      object-fit: cover;
    }
  }

  table {
    font-size: 1.6rem;
    margin: 3rem 0;

    tr:nth-child(even) td {
      background-color: $color-pampas;
    }

    td:last-child {
      font-weight: 700;
    }
  }

  .make-it-wrapper {
    margin-bottom: 2rem;
    text-align: left;
    @include flexbox;
    @include justify-content(center);
    @include flex-wrap(wrap);

    .make-it-item {
      width: 32rem;
      @include flexbox;
      @include align-items(center);
      @include flex-direction(column);

      p {
        margin-bottom: 1rem;
        max-width: 24rem;
      }

      .make-it-imgs {
        font-size: 6rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);

        img {
          height: 18rem;
          width: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .hero-wrapper.chickn-dipn {
    height: auto;
    min-height: 25rem;
    padding: 4%;
  }

  .body-wrapper.chickn-dipn {
    .descr.short {
      width: 100%;
    }

    .product-intro {
      br {
        display: none;
      }
    }

    .recipe-wrapper {
      max-width: none;

      .recipe-item {
        width: 33%;
        @include flexbox;
        @include align-items(center);
        @include flex-direction(column-reverse);
        @include flex-wrap(wrap);
        @include justify-content(flex-end);

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-left: 0;
          margin-top: 0;

          img {
            height: auto;
            width: 100%;
          }

          span {
            position: relative;
            bottom: auto;
            top: auto;
            left: auto;
            right: auto;
            text-align: center;
            width: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.chickn-dipn {
    h1 {
      width: 100%;
    }
  }

  .body-wrapper.chickn-dipn {
    .descr{
      width: 100%;
    }

    p {
      font-size: 1.4rem;
    }

    .product-intro {
      display: block;
      text-align: center;

      img {
        height: 20rem;
        margin-top: -6rem;
      }

      .button-group {
        @include justify-content(center);
      }
    }

    .recipe-wrapper {
      display: block;

      .recipe-item {
        margin-bottom: 1rem;
        width: 100%;

        span {
          font-size: 1.6rem;
        }
      }
    }

    .product-wrapper img {
      width: 20rem;
    }

    .make-it-wrapper .make-it-item {
      display: block;
      width: 100%;

      p {
        display: inline-block;
        max-width: none;
        text-align: center;
        vertical-align: top;
        width: 50%;

        &:first-of-type {
          width: 100%;
        }
      }
    }
  }
}
