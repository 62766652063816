@import 'variables';

.filters {
  font-family: $font-serif;
  font-size: 3rem;
  width: 100%;
  @include flexbox();
  @include justify-content(center);

  & > div {
    font-size: 3rem;
    width: 100%;
    @include flexbox();
    @include justify-content(center);

    .select-big {
      margin: 1rem;
      width: 18%;
      @include flexbox();
      @include align-items(center);
      background-color: transparent;
    }
  }

  .select__single-value {
    overflow: visible;
  }

  .select__value-container--has-value {
    top: 0;
    padding: 5.5rem 0 0;
    position: absolute;
    font-family: $font-sans-serif;
    font-size: 1.6rem;

    .select__multi-value {
        background-color: #fff;
    }
  }

  .select__value-container {
      .select__input {
          display: block !important;
          margin-top: -4px;
          font-size: 1.6rem;

          input {
              text-transform: none;
          }
      }
  }

  .select__clear-indicator {
      display: none;
  }

  .select-wraper {
    position: relative;
    margin: 1rem .5rem;
    width: 18%;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    color: rgb(6, 33, 57);

    .title {
      position: absolute;
      width: 100%;
      padding: 1rem;
      background-color: #fff;
      text-align: left;

      .hidden {
          opacity: 0;
      }
    }

    .select-big {
        position: absolute;
        margin: 0;
        width: 100%;
        z-index: 2;

        .select__control {
            min-height: 5rem;
        }

        .select__menu-list {
          max-height: 40rem;

          &::-webkit-scrollbar {
            width: 1rem;
          }
          
          &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          }
          
          &::-webkit-scrollbar-thumb {
            background-color: $color-metal;
          }
        }
    }

    .values {
      margin-top: 5.5rem;
      word-break: break-word;
      opacity: 0;

      span {
        margin: 2px;
        padding: 5px 3px;
        font-size: 52%;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }
    }

    &.mobile {
      width: 100%;

      .title {
        padding: 1.5rem 1rem;
        font-size: 65%;
      }

      .select__indicators {
        position: absolute;
        right: 0;
      }
    }

  }
}

.body-wrapper.recipes {
  .filters {
    .select-wraper {
      width: 28%;
      
      &.mobile {
        width: 100%;
      }
    }

    & > div {
      .select-big {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .body-wrapper.recipes .filters > div .select-big {
    width: 100%;
  }

  .filters {
    & > div {
      display: block;
    }

    .select__group-heading {
      color: $color-primary;
      font-family: $font-sans-serif;
      font-size: 1.6rem;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}
