@import '../../variables';

header {
  background-color: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s;
  width: 100%;
  z-index: 3;

  .header-wrapper {
    font-size: 1.2rem;
    font-weight: bold;
    height: 9rem;
    line-height: 1.4;
    letter-spacing: 0.1rem;
    padding: 0 4%;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    .ext-link {
      @include flexbox();
      @include align-items(center);

      a {
        display: block;

        img {
          height: 4.2rem;
          margin-left: 1.5rem;
          width: auto;
          @include transition;
        }

        &:hover img {
          @include transform(scale(1.1));
        }
      }
    }

    .header-mobile {
      background-color: $color-white;
      height: 100%;
      padding: 1rem 4%;
      position: relative;
      width: 100%;
      z-index: 11;
    }
  }

  .logo {
    cursor: pointer;
    margin-right: 3rem;
    width: 14rem;
  }

  .main-nav {
    @include flexbox();
    @include align-items(center);
  }

  .mobile {
    .menu-btn {
      background: transparent;
      border: 0;
      cursor: pointer;
      width: 2.8rem;
      padding: 0;
      @include flexbox();
      @include flex-direction(column);

      span {
        background: $color-primary;
        display: block;
        height: 0.2rem;
        margin: 0.2rem 0;
        width: 2.8rem;
        @include transition;
      }

      &.active span {
        background: $color-link;
        margin: 0.3rem 0;
      }
    }

    .mobile-nav-wrapper {
      position: absolute;
      right: 1rem;
      top: 7rem;
      max-width: 65vw;
      padding: 2rem 3rem;
      background-color: $color-pampas;
      overflow-y: auto;
      z-index: 0;
      @include transition;
      @include transform(translateY(-100%));

      &.visible {
        z-index: 10;
        @include transform(translateY(0));
      }
    }
  }

  .search-icon {
    background-image: url("../../assets/img/icon-search-blue.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
    height: 2.4rem;
    margin: 0 1vw;
    text-align: center;
    width: 2rem;
    z-index: 10;
    @include transition;

    &:hover {
      background-image: url("../../assets/img/icon-search-orange.png");
    }

    &.close {
      background-image: url("../../assets/img/icon-cross-blue.png");

      &:hover {
        background-image: url("../../assets/img/icon-cross-orange.png");
      }
    }
  }

  .search-block {
    background-color: $color-white;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.25);
    left: 0;
    opacity: 0;
    padding: 6% 4%;
    position: absolute;
    top: 9rem;
    visibility: hidden;
    width: 100%;
    @include transition;

    &.open {
      opacity: 1;
      visibility: visible;
    }

    form {
      @include flexbox();
      @include align-items(center);

      .search-input {
        border: 0;
        border-bottom: 1px solid $color-primary;
        font-family: $font-serif;
        font-size: 3.6rem;
        font-weight: normal;
        line-height: normal;
        padding: 0;
        text-transform: none;
        width: 70%;

        &::placeholder {
          text-transform: none;
        }
      }

      .search-submit {
        background-image: url("../../assets/img/icon-search-blue.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        color: transparent;
        cursor: pointer;
        display: block;
        height: 3rem;
        margin: 0;
        padding: 0;
        width: auto;

        &:hover {
          background-image: url("../../assets/img/icon-search-orange.png");
        }
      }
    }
  }
}

@media screen and (max-width: 999px) {
  header {
    height: 7rem;

    .header-wrapper {
      height: 7rem;
      padding: 0;
      width: auto;

      .ext-link {
        padding: 1rem 0;
        left: 0;
        position: relative;
        text-align: left;
        top: 0;
        width: 100%;
      }
    }

    .logo {
      float: left;
      width: 12rem;
    }

    .mobile .menu-btn {
      float: right;
      margin-top: 1.6rem;
    }

    .search-icon {
      float: right;
      margin: 1.3rem 2.5rem 0 0;
    }

    .search-block {
      padding: 10% 4%;
      top: 7rem;

      form {
        @include justify-content(space-between);

        .search-input {
          font-size: 2rem;
          width: 95%;
        }

        .search-submit {
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }
}
