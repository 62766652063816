@import '../../variables';

.hero-wrapper.cups {
  background-image: url('../../assets/img/hero-cups.jpg');
  height: 55rem;
  @include justify-content(center);
}

.body-wrapper.cups {
  .flavor-item img {
    height: 100%; //IE fix
    width: 100%;
  }

  .dipping-wrapper {
    margin-left: 4rem;
    margin-top: 2rem;
    @include flexbox();
    @include justify-content(center);
    @include flex-wrap(wrap);

    .dip-item {
      margin-left: -1rem;
      position: relative;
      width: 23vw;

      span {
        color: $color-primary;
        display: block;
        font-family: $font-serif;
        font-size: 2.3vw;
        left: -1.6vw;
        line-height: 0.8;
        position: absolute;
        text-align: center;
        top: 5.3vw;
        width: 100%;
      }

      &:nth-child(odd) span {
        color: $color-white;
      }

      &:first-child span {
        font-size: 7.2vw;
        top: 4.6vw;
      }
    }
  }

  .taste-wrapper .taste-block {
    height: 20vw; //IE fix
    width: 20vw;
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.cups {
    height: 20rem;
  }

  .body-wrapper.cups {
    .taste-wrapper .taste-block {
      height: 100%; //IE fix
      width: 100%;
    }

    .dipping-wrapper .dip-item {
      width: 28rem;

      span {
        font-size: 3rem;
        left: -2rem;
        top: 6rem;
      }

      &:first-child span {
        font-size: 9rem;
        top: 6rem;
      }
    }
  }
}
