@import '../../variables';
@import '../recipes/recipes';
@import '../products/products';

.body-wrapper.search-result {
  @include justify-content(center);
  @include flex-direction(column);

  & > div {
    width: 100%;
  }

  h3 {
    font-size: 4rem;
    text-align: left;
    width: 100%;
  }

  .product-list,
  .recipe-list {
    margin-top: 0;
    width: 100%;
    @include flexbox();
    @include flex-wrap(wrap);
  }

  .recipe-list {
      @include justify-content(left);
  }

  .block:nth-child(even),
  .popular-search {
    background-color: $color-white;
  }

  .popular-search {
    font-size: 1.2rem;
    font-weight: normal;
    letter-spacing: 0.1rem;
    margin: 2rem 0;
    padding: 0.5rem 2rem;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    @include flexbox();
    @include flex-wrap(wrap);

    ul {
      margin-right: 10rem;

      li {
        margin: 1.5rem 0;
      }
    }
  }

  .search-wrapper {
    margin: 10rem auto;

    .input-big {
      box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.25);
    }
  }
}

@media screen and (max-width: 767px) {
  .body-wrapper.search-result {
    h3 {
      font-size: 2rem;
    }

    .search-wrapper {
      margin: 3rem 0;
    }
  }
}
