@charset "UTF-8";
/* Fonts */
/* Colors */
/* Mixins */
.hero-wrapper.about {
  background-image: url("../../assets/img/hero-about.jpg");
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .hero-wrapper.about .descr {
    margin-top: 4rem; }

.hero-wrapper.about2 {
  background-image: url("../../assets/img/hero-about2.jpg"); }

.body-wrapper.about {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .body-wrapper.about .info-block {
    position: relative;
    text-align: center;
    width: 90%; }
    .body-wrapper.about .info-block .circle {
      border-radius: 100%;
      background-color: #cdd3d7;
      color: #ffffff;
      font-family: "DM Serif Display", serif;
      font-size: 9rem;
      line-height: 0.8;
      height: 27rem;
      position: absolute;
      top: -4rem;
      left: 0;
      width: 27rem;
      z-index: 1;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center; }
    .body-wrapper.about .info-block ul {
      padding-left: 30rem;
      text-align: left; }
      .body-wrapper.about .info-block ul li {
        margin: 1rem 0; }
        .body-wrapper.about .info-block ul li strong {
          font-size: 2rem;
          font-weight: 700; }
  .body-wrapper.about .img-wrapper {
    height: 100%;
    margin: 3rem 0;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .body-wrapper.about .img-wrapper .img-item {
      height: 100%;
      text-align: center;
      width: 27rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .body-wrapper.about .img-wrapper .img-item small {
        font-size: 85%;
        margin-bottom: 2rem;
        margin-top: 3rem;
        width: 85%; }
      .body-wrapper.about .img-wrapper .img-item img {
        -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.75));
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.75));
        height: 100%; }
      .body-wrapper.about .img-wrapper .img-item:nth-child(1) img {
        -webkit-transform: rotate(-7deg);
        -moz-transform: rotate(-7deg);
        -o-transform: rotate(-7deg);
        -ms-transform: rotate(-7deg);
        transform: rotate(-7deg); }
      .body-wrapper.about .img-wrapper .img-item:nth-child(3) img {
        -webkit-transform: rotate(7deg);
        -moz-transform: rotate(7deg);
        -o-transform: rotate(7deg);
        -ms-transform: rotate(7deg);
        transform: rotate(7deg); }
      .body-wrapper.about .img-wrapper .img-item:nth-child(4) img {
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        transform: rotate(-5deg); }
  .body-wrapper.about .service-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .body-wrapper.about .service-wrapper .service {
      margin: 1rem;
      text-align: left;
      width: 21rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: flex-start;
      -moz-justify-content: flex-start;
      -ms-justify-content: flex-start;
      justify-content: flex-start;
      -ms-flex-pack: flex-start;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .body-wrapper.about .service-wrapper .service .service-img {
        margin-bottom: 1rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
        -moz-justify-content: flex-end;
        -ms-justify-content: flex-end;
        justify-content: flex-end;
        -ms-flex-pack: flex-end; }
        .body-wrapper.about .service-wrapper .service .service-img img {
          max-height: 7rem;
          max-width: 7rem; }
  .body-wrapper.about .quote {
    font-weight: bold;
    margin: 1rem 0;
    text-align: center;
    width: 80%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .body-wrapper.about .quote p {
      margin: 0; }
    .body-wrapper.about .quote .accent {
      font-family: "DM Serif Display", serif;
      font-size: 3.5rem;
      font-weight: 300;
      line-height: 3.5rem;
      margin: 0; }
    .body-wrapper.about .quote .sign {
      font-size: 1.4rem;
      font-style: italic;
      font-weight: normal;
      margin: 0.5rem 0; }
    .body-wrapper.about .quote img {
      display: block;
      height: auto;
      margin-top: 1rem;
      max-height: 5rem;
      max-width: 10rem;
      width: auto; }
  .body-wrapper.about.features {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start; }
    .body-wrapper.about.features .feature-block {
      margin: 2rem;
      text-align: left;
      width: 35rem; }
      .body-wrapper.about.features .feature-block ul li:before {
        content: "·";
        font-size: 3rem;
        padding-right: 1rem;
        vertical-align: middle;
        line-height: 1rem; }
      .body-wrapper.about.features .feature-block img {
        margin-bottom: 1rem; }
  .body-wrapper.about .block.flip:nth-child(even) .front {
    background-color: #cdd3d7; }
  .body-wrapper.about .block.flip .block-txt .block-title {
    overflow: inherit; }
  .body-wrapper.about .block.flip .back {
    padding-top: 1.5rem; }

@media screen and (max-width: 767px) {
  .hero-wrapper.about {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .hero-wrapper.about .descr {
      margin-top: auto; }
  .body-wrapper.about h2 {
    text-align: center; }
  .body-wrapper.about .descr,
  .body-wrapper.about .service-wrapper .service,
  .body-wrapper.about .quote,
  .body-wrapper.about .feedback-wrapper {
    width: 100%; }
  .body-wrapper.about .info-block {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .body-wrapper.about .info-block .circle {
      font-size: 4rem;
      height: 13rem;
      position: relative;
      width: 13rem; }
    .body-wrapper.about .info-block ul {
      margin-top: -4rem;
      padding-left: 0; }
  .body-wrapper.about.features .feature-block {
    margin: 0;
    max-width: none;
    width: 100%; }
  .body-wrapper.about .feedback-wrapper {
    display: block; }
    .body-wrapper.about .feedback-wrapper strong {
      display: block;
      margin: 1rem 0 2rem 0; }
  .body-wrapper.about .img-wrapper .img-item small {
    margin-bottom: 3rem; } }
