@import '../../variables';

.hero-wrapper.fall {
  background-image: url('../../assets/img/offers/fall-flavors/top-image.jpg');
  @include justify-content(center);

  & > div {
    background-color: rgba($color-white, 0.8);
    padding: 5rem;
    position: relative;
    width: 60%;
    @include flexbox;
    @include flex-direction(column);

    &::after {
      border: 1px solid $color-primary;
      content: '';
      position: absolute;
      left: 1rem;
      right: 1rem;
      top: 1rem;
      bottom: 1rem;
    }
  }

  h1,
  .descr {
    color: $color-primary;
    text-shadow: none;
    z-index: 1;
  }

  .logo {
    margin: 0 auto;
    z-index: 1;
  }
}

.body-wrapper.fall {
  .benefits-wrapper {
    width: 80%;
    @include justify-content(space-between);
    @include align-items(flex-start);

    & > div {
      border: 2px solid $color-pampas;
      margin-bottom: 12rem;
      min-height: 28rem;
      padding: 0 2rem 6rem 2rem;
      position: relative;
      text-align: center;
      width: 30%;

      .descr {
        margin: 0;
        text-align: left;
        width: 100%;

        span {
          border: 0;
        }
      }

      img {
        height: auto;
        bottom: -10rem;
        left: 15%;
        position: absolute;
        width: 80%;
        z-index: 1;
      }
    }
  }

  .remark {
    bottom: 0;
    position: relative;
    right: 0;
  }

  .feature-wrapper {
    .feature-block {
      cursor: pointer;
      min-height: 27rem;
      width: 23%;
      @include flexbox();
      @include flex-direction(column);

      span {
        bottom: 0;
        color: $color-primary;
        display: block;
        font-size: 2rem;
        left: 0;
        margin-bottom: 1rem;
        position: relative;
        text-align: left;
        text-shadow: none;
      }

      ul {
        list-style: disc;
        padding-left: 2rem;
        text-align: left;
      }

      img {
        height: 13rem;
        object-fit: contain;
        width: auto;
        @include transition;
      }

      &:hover {
        span {
          text-decoration: underline;
        }

        img {
          height: 14rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .hero-wrapper.fall {
    padding: 4%;
  }

  .body-wrapper.fall {
    .benefits-wrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.fall {
    & > div {
      width: 100%;
    }
  }

  .body-wrapper.fall {
    .benefits-wrapper {
      width: 80%;

      & > div {
        min-height: 25rem;
        width: 100%;
      }
    }

    .feature-wrapper {
      .feature-block {
        height: auto;
        min-height: auto;
        margin: 3rem 0;
        width: 100%;
        @include align-items(center);
      }
    }
  }
}
