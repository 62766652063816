@import '../../variables';

.hero-wrapper.recipes {
  background-image: url('../../assets/img/hero-recipes.jpg');
  height: 45rem;

  .button {
    background-color: $color-white;
    bottom: 4vw;
    color: $color-primary;
    position: absolute;
    right: 3vw;

    &:hover {
      background-color: $color-link;
      border-color: $color-link;
      color: $color-white;
    }
  }

  .search-wrapper {
    margin-top: 8rem;
  }
}

.loader {
    text-align: center;
    padding: 1rem 0;
}

.body-wrapper.recipes {
  @include flex-direction(column);
  @include justify-content(flex-start);

  &.single {
    text-align: left;
    @include flex-direction(row);
    @include align-items(flex-start);
    @include justify-content(space-between);

    h2 {
      text-align: left;
      width: 35rem;
      @include flex(1 1 auto);
    }

    a {
      text-decoration: underline;
      @include transition;

      &.button {
        text-decoration: none;
      }
    }

    .dressing {
      @include flexbox();
      @include flex(1 1 auto);
      @include align-items(flex-start);
      @include justify-content(center);
      @include flex-wrap(wrap);

      img {
        width: 11rem;

        &.kogi {
          margin-top: 11px;
          width: 8rem;
          margin-left: 13px;
        }
      }
    }

    .button-group {
      margin: 2rem 0;
      width: 100%;
    }

    .recipe-info {
      font-size: 1.3rem;
      text-transform: capitalize;
      width: 35rem;
      @include flex(1 1 auto);

      .row {
        min-height: 3.5rem;
        @include flexbox();
        @include align-items(center);

        .label {
          letter-spacing: 0.1rem;
          text-transform: uppercase;
        }

        .dot-line {
          border-bottom: 2px dotted $color-primary;
          margin: 0.5rem 0.5rem 0;
          @include flex-grow(1);
        }

        .value {
          text-align: right;

          a {
            display: block;
            line-height: 2rem;
          }
        }
      }
    }

    .info-block {
      margin: 5rem 0;
      width: 50%;

      &.ingredients {
          ul li span {
            text-transform: lowercase;
            
            &:first-child {
              margin-right: 1rem;
              min-width: 7rem;
            }

            &::first-letter,
            a,
            .link {
              text-transform: capitalize;
            }
          }
      }

      ul,
      ol {
        margin-bottom: 2rem;

        li {
          @include flexbox();
          padding: 0.2rem;

          &::first-letter,
          a,
          .link {
            text-transform: capitalize;
          }

          &.sub-recipe {
            background-color: $color-pampas;
          }
        }
      }

      ol {
        counter-reset: li;

        & > li {
          display: block;
          line-height: normal;
          list-style: none;
          margin-bottom: 3rem;
          margin-left: 6rem;
          position: relative;
          @include align-items(center);

          &:before {
            border: 2px solid $color-primary;
            border-radius: 5rem;
            color: $color-primary;
            content: counter(li);
            counter-increment: li;
            font-family: $font-serif;
            font-size: 3.5rem;
            height: 4rem;
            left: -6rem;
            line-height: 1;
            margin-right: 2rem;
            position: absolute;
            top: 50%;
            width: 4rem;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            @include transform(translateY(-50%))
          }
        }
      }
    }
  }

  &.sub-recipe {
    padding-top: 0;
    h3 {
      text-align: left;
      text-transform: lowercase;
      width: 35rem;
      @include flex(1 1 auto);
      &::first-letter {
        text-transform: uppercase;
      }
    }

    .body-wrapper {
      padding: 2rem;
      background-color: $color-pampas;

      .info-block {
        margin-top: 0;
      }
    }
  }

  .select__menu {
    .select__group {

      & > div {
        display: none;
      }

      .select__group-heading {
        display: block;

        & > div {
          display: block;
          position: relative;

          &::after {
            content: '';
            background-image: url("../../assets/img/icon-arrow.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            height: 1.5rem;
            position: absolute;
            right: 0;
            top: 15%;
            width: 1.5rem;
            @include transform(rotate(-90deg));
          }

          &.active::after {
            @include transform(rotate(90deg));
          }
        }
      }

      .select__option {
            position: relative;
            padding: .8rem 1.2rem .8rem 3.2rem;

            &::before {
                content: '';
                display: block;
                position: absolute;
                height: 1.2rem;
                width: 1.2rem;
                top: 1.2rem;
                left: 1.2rem;
                border: 1px solid black;
            }

            &.select__option--is-selected {
                background-color: transparent;
                &::after {
                      content: '';
                      display: block;
                      position: absolute;
                      left: 1.5rem;
                      top: 1rem;
                      width: .5rem;
                      height: 1rem;
                      border: solid #000;
                      border-width: 0 3px 3px 0;
                      -webkit-transform: rotate(45deg);
                      transform: rotate(45deg);
                }
                &::before {
                    background: #fff;
                }
            }
        }
    }
  }
}

.recipe-list {
  margin: 2rem 0;
  width: 100%;
  @include flexbox();
  @include flex-wrap(wrap);

  &.mh {
      min-height: 35vh;
  }
}

.recipe-img {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 40vw;
}

@media screen and (max-width: 1000px) {
  .hero-wrapper.recipes {
    height: auto;
    @include justify-content(center);

    .button {
      bottom: auto;
      position: relative;
      right: auto;
    }

    .search-wrapper {
      margin-top: 0;
      margin-bottom: 3rem;
    }
  }

  .recipe-img {
    background-size: contain;
    height: 60vw;
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.recipes {
    padding-bottom: 15%;
  }

  .body-wrapper.recipes {
    @include justify-content(center);

    &.single {
      h2 {
        text-align: center;
      }

      .button-group {
        @include justify-content(center);

        button {
          margin: 0.5rem;
          @include flex(1 1 auto);
        }
      }

      .info-block {
        margin: 1rem 0;
        width: 100%;
      }
    }

    .filters > div .select-big {
      width: 100%;
    }
  }
}
