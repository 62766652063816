@import '../../variables';

.hero-wrapper.portion-control {
  background-image: url('../../assets/img/hero-portion.jpg');
  background-position: 35% 55%;
  background-size: 140%;
  height: 50rem;
}

.body-wrapper.portion-control {
  .products-list,
  .facts-wrapper {
    width: 100%;
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(center);
    @include flex-wrap(wrap);

    .flavor-item {
      width: 15rem;

      img {
        width: 100%;
      }
    }

    .fact-block {
      background-color: $color-secondary;
      font-size: 1.8rem;
      height: 14vw;
      line-height: 1.3;
      margin: 0 1vw;
      overflow: hidden;
      position: relative;
      text-align: left;
      width: 42vw;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
      @include flex-wrap(nowrap);

      .fact-content {
        padding: 2rem;
        width: 60%;
        z-index: 1;
      }

      span {
        font-family: $font-serif;
        font-size: 7rem;
        line-height: 1.2;
      }

      img {
        height: 100%;
        object-fit: contain;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }
    }
  }

  .request-form {
    margin-bottom: 4rem;
    margin-top: 2rem;
    width: 70%;

    .submit {
      margin-top: 2rem;
      @include justify-content(flex-start);
    }
  }

  .is-invalid {
    .select {
      border-color: rgb(153, 0, 0);
    }

    p.error {
      display: block;
    }
  }

  p.error {
    display: none;
    margin: 0;
    padding-top: 0.5rem;
    font-size: 85%;
    color: rgb(153, 0, 0);
  }
}

@media screen and (max-width: 1000px) {
  .hero-wrapper.portion-control {
    height: 20rem;
  }

  .body-wrapper.portion-control {
    .facts-wrapper .fact-block {
      height: auto;
      margin: 1rem;
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .body-wrapper.portion-control {
    .facts-wrapper .fact-block {
      img {
        right: -20%;
      }

      &:last-child img {
        right: -50%;
      }
    }

    .request-form {
      width: 100%;

      .two-columns > div {
        margin-bottom: 0;
      }      
    }
  }
}
