@import '../../variables';

.hero-wrapper.pouches {
  background-color: $color-secondary;
  padding: 0;
  padding-right: 4%;
  @include flex-direction(row);

  .hero-img {
    height: 100%;
    margin-right: 5rem;
    width: 210rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  h1,
  .descr {
      color: $color-primary;
      font-weight: normal;
      text-align: left;
  }

  h1 {
    font-size: 10rem;
    line-height: 0.8;

    span {
      white-space: nowrap;
    }
  }

  .pouch-img {
    width: 28rem;
    @include transform(rotate(-10deg));
  }

  .button {
    border: 1px solid $color-primary;
    color: $color-primary;
    margin-left: 13rem;

    &:hover {
      background: $color-primary;
      color: $color-white;
    }
  }
}

@media screen and (max-width: 1000px) {
  .hero-wrapper.pouches {
    background-image: url('../../assets/img/hero-pouches.jpg');
    padding: 4%;

    .hero-img {
      display: none;
    }

    h1,
    .descr {
        color: $color-white;
        text-shadow: 0 5px 10px rgba(0, 0, 0, 0.75);
    }

    .descr {
      font-weight: 700;
    }

    .pouch-img {
      margin-bottom: 1rem;
      width: 30vw;
    }

    .button {
      color: $color-white;
      border-color: $color-white;
      width: 15rem;

      &:hover {
        background: $color-white;
        color: $color-primary;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.pouches {
    h1 {
      font-size: 3.5rem;
    }

    .descr {
      font-size: 1.4rem;
    }

    .button {
      margin-left: 0;
    }
  }
}
