@import '../../variables';

.hero-wrapper.signature {
  background-image: url('../../assets/img/hero-signature.jpg');
  height: 60rem;
  @include justify-content(center);
}

.body-wrapper.signature {
  .taste-wrapper .taste-block {
    .taste-img {
      margin-bottom: 0;
      margin-left: -2vw;
      margin-right: 2vw;
    }

    .taste-content {
      p {
        min-height: 0;
        width: 17vw;
      }

      img {
        height: 20vw;
        top: -7vw;
      }
    }

    &:nth-child(2) {
      .taste-img {
        margin-top: -2vw;
      }

      .taste-content {
        p {
          min-height: 13vw;
        }

        img {
          top: -1vw;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .hero-wrapper.signature {
    height: 20rem;
  }
}

@media screen and (max-width: 767px) {
  .body-wrapper.signature {
    .taste-wrapper .taste-block {
      .taste-img {
        height: auto;
        margin-left: -6vw;
      }

      .taste-content {
        p {
          width: 75%;
        }

        img {
          height: auto;
          top: -10rem;
          width: 30%;
        }
      }

      &:nth-child(2) {
        .taste-img {
          margin-top: -2vw;
          order: 1;
        }

        .taste-content {
          order: 2;

          p {
            min-height: 0;
          }

          img {
            top: -10rem;
          }
        }
      }
    }
  }
}
