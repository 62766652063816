@import '../../variables';

.search-wrapper {
  font-family: $font-serif;
  margin: 3rem 0;
  max-width: 60rem;
  position: relative;
  width: 100%;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  .input-big {
    background: rgba($color-white, 0.75) url('../../assets/img/icon-search-blue.png') no-repeat 98% 50%;
    background-size: 3rem;
    font-family: $font-serif;
    padding-right: 6rem;
  }

  .search-dropdown {
    background-color: $color-soft;
    color: $color-primary;
    font-family: $font-sans-serif;
    max-width: 60rem;
    max-height: 45vh;
    opacity: 0;
    overflow-y: auto;
    position: absolute;
    text-align: left;
    left: 0;
    top: 5rem;
    visibility: hidden;
    width: 100%;
    z-index: 2;
    @include transition;

    &.visible {
      visibility: visible;
      opacity: 1;
    }

    .group-title {
      color: $color-primary;
      font-weight: bold;
      text-transform: capitalize;
    }

    ul {
      padding: 1rem 3rem;

      &:first-child {
        margin-top: 2rem;
      }
    }

    li {
      margin-bottom: 0.5rem;

      a {
        @include flexbox();

        span {
          &:nth-child(1) {
            @include flex(1 1 auto);
            @include order(2);
          }

          &:nth-child(2) {
            min-width: 10rem;
            @include order(1);
          }

          &:nth-child(3) {
            margin-left: 0.5rem;
            white-space: nowrap;
            @include order(3);
          }
        }
      }
    }

    ul ul {
      padding-left: 2rem;
      padding-top: 0;
    }
  }

  &.wide {
    max-width: 70rem;

    .input-big {
      background-image: url('../../assets/img/icon-search-grey.png');
      background-position: 2% 50%;
      padding-left: 6rem;
      padding-right: 2%;

      &::placeholder {
        color: $color-grey;
        font-size: 2.8rem;
      }
    }

    .search-dropdown {
      max-width: 70rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .search-wrapper {
    margin: 2rem 0;
    max-width: none;

    .input-big {
      background-size: 2rem;
      background-position: 96% 50%;
    }

    .search-dropdown {
      max-width: none;
      top: 3.5rem;

      ul {
        padding: 0.5rem 2rem;

        &:first-child {
          margin-top: 1rem;
        }
      }

      ul ul {
        padding: 0;
      }

      li a span {
        line-height: 1.2;

        &:nth-child(2) {
          min-width: 9rem;
        }
      }
    }

    &.wide {
      max-width: none;

      .input-big {
        padding-left: 4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &::placeholder {
          font-size: 2rem;
        }
      }

      .search-dropdown {
        max-width: none;
      }
    }
  }
}
