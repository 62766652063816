@import '../../variables';

.pizza-expo {
  color: #000;
  background-color: $color-white;
  font-size: 1.8rem;

  h1 {
    color: #000;
    font-family: 'Gotham-Medium', Helvetica, sans-serif;
    font-size: 2.6vw;
    line-height: 1.2;
  }

  h2 {
    color: #000;
    font-family: 'Gotham-Book', Helvetica, Arial, sans-serif;
    font-size: 2rem;
    line-height: 1.4;
    margin-top: 0;
  }

  header {
    border-bottom: 0.8rem solid #fef102;
    height: 40rem;
    position: relative;
    width: 100%;
    @include flexbox();

    .hero-image {
      background-image: url("../../assets/img/offers/pizza-expo/hero.jpg");
      background-position: top left;
      background-repeat: no-repeat;
      background-size: cover;
      width: 60vw;
    }

    .hero-block {
      background-color: #000;
      text-align: center;
      width: 40vw;
      @include flexbox();
      @include flex-direction(column);

      .hero-logo {
        background-color: #002d55;
        padding: 2rem;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        img {
          height: 10rem;
          width: auto;
        }
      }
    }

    .hero-title {
      height: 100%;
      padding: 3rem;
      @include flexbox();
      @include align-items(center);

      h1 {
        color: $color-white;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
      }
    }
  }

  .content {
    font-family: 'Gotham-Book', Helvetica, Arial, sans-serif;
    padding: 4% 8%;
    width: 100%;
    @include flexbox();
    @include justify-content(space-between);
    @include flex-wrap(wrap);

    & > div {
      @include flex(1 1 50%);
    }

    h2 {
      margin-bottom: 2rem;
    }

    p {
      color: #000;
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }

    .info-block {
      @include flexbox();
      @include align-items(flex-end);
    }

    .content-img {
      height: 13rem;
      margin-right: 1rem;
      width: 20rem;
      overflow: hidden;
    }

    .content-info {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-top: 3rem;
    }

    .two-columns > div {
      width: 100%;
    }

    form {
      input {
        border-color: $color-secondary;
        font-family: 'Gotham-Book', Helvetica, Arial, sans-serif;
        font-size: 1.4rem;
        font-weight: normal;
        height: 4rem;
        letter-spacing: normal;
        margin-top: 1rem;
        text-transform: none;

        &:nth-child(odd) {
          margin-right: 1rem;
        }

        &::-webkit-input-placeholder {
          color: $color-grey;
        }

        &:-moz-placeholder {
          color: $color-grey;
        }

        &::-moz-placeholder {
          color: $color-grey;
        }

        &:-ms-input-placeholder {
          color: $color-grey;
        }

        &::-ms-input-placeholder {
          color: $color-grey;
        }

        &[type=submit] {
          background-color: $color-primary;
          border: 0;
          color: $color-white;
          cursor: pointer;
          font-family: 'Gotham-Bold', Helvetica, Arial, sans-serif;
          font-size: 1.6rem;
          text-transform: uppercase;

          &:hover {
            background-color: #fef102;
            color: #000;
          }
        }

        &.invalid {
          border-color: $color-red;
        }
      }

      .recaptcha {
        margin-top: 1rem;
      }
    }
  }

  footer {
    background-color: transparent;
    font-family: 'Gotham-Book', Helvetica, Arial, sans-serif;
    padding: 3% 8% 1% 8%;
    width: 100%;

    & > div {
      border-bottom: 1px solid $color-primary;
      width: 100%;
      @include flexbox();
      @include align-items(flex-end);
      @include justify-content(space-between);

      .footer-logos {
        margin-bottom: 1rem;
        @include flexbox();
        @include align-items(flex-end);

        a {
          margin: 0;

          img {
            max-height: 7rem;
            margin-right: 2rem;
            max-width: 10rem;
          }
        }
      }

      .footer-menu {
        color: $color-primary;
        font-size: 1.1rem;
        font-weight: normal;
        list-style: none;
        margin-bottom: 1rem;
        padding: 0;
        text-align: right;
        text-transform: none;

        li {
          display: inline-block;

          &::before {
            content: '|';
            margin: 0 1rem;
          }

          &:first-child::before {
            content: none;
          }

          a {
            letter-spacing: normal;
            margin: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 1800px) {
  .pizza-expo h1 {
      font-size: 5rem;
  }
}

@media (max-width: 768px) {
  .pizza-expo {
    h1 {
      font-size: 4vw;
    }

    header {
      @include flex-direction(column);

      .hero-image,
      .hero-block {
        width: 100%;
      }

      .hero-image {
        background-position: center;
        height: 30rem;
        @include order(2);
      }

      .hero-block {
        height: 15rem;
        @include flex-direction(row);
        @include order(1);

        .hero-logo {
          @include order(2);
        }
      }

      .hero-title {
        @include order(1);
      }
    }

    .content {
      & > div {
        width: 100%;
        @include flex(0 0 auto);
      }

      .info-block {
        margin-bottom: 4rem;
      }
    }

    footer {
      margin-top: 4rem;
    }
  }
}

@media (max-width: 650px) {
  .pizza-expo footer > div {
    border-bottom: 0;
    display: block;

    .footer-logos {
      margin-bottom: 2rem;
      @include justify-content(center);
    }

    .footer-menu {
      border-top: 1px solid #002d55;
      padding-top: 2rem;
      text-align: center;
      width: 100%;

      li {
        display: block;
        font-size: 1.4rem;
        margin: 1rem 0;

        &::before {
          content: none;
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .pizza-expo {
    h1 {
      font-size: 5vw;
    }

    header {
      .hero-block .hero-logo img {
        height: 5rem;
      }
    }

    .content {
      text-align: center;
    }
  }
}
