@import '../../variables';

.hero-wrapper.hot {
  background-image: url('../../assets/img/hero-whatshot.jpg');
  padding: 4%;

  h1 {
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.75);
  }
}
