/* Fonts */
/* Colors */
/* Mixins */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  background-color: #ffffff;
  color: #062139;
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  overflow-x: hidden; }

h1,
h2,
h3 {
  font-family: "DM Serif Display", serif;
  letter-spacing: -0.1rem;
  margin: 1rem 0; }

h1 {
  font-size: 7rem;
  letter-spacing: -0.2rem; }
  h1 .accent {
    color: #f0e2b3; }

h2 {
  font-size: 5rem;
  margin: 2rem 0; }

h3 {
  font-size: 2.8rem; }

h4 {
  font-size: 1.2rem; }

a {
  color: #062139;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  a:hover {
    color: #de8826; }
  a:focus {
    outline: none; }

.link {
  cursor: pointer;
  text-decoration: underline;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .link:hover {
    color: #de8826; }

button,
.button {
  background: transparent;
  border: 1px solid #062139;
  color: #062139;
  cursor: pointer;
  font-family: "Merriweather Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  line-height: 1.6rem;
  padding: 0.4rem 1rem;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  button:hover,
  .button:hover {
    background: #062139;
    color: #ffffff; }
  button.inverse,
  .button.inverse {
    background: #062139;
    color: #ffffff; }
    button.inverse:hover,
    .button.inverse:hover {
      background: transparent;
      color: #062139; }

.button-group {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .button-group button,
  .button-group .button {
    margin: 0.5rem 0 0.5rem 1rem; }

.loading {
  padding: 1rem;
  margin: 0;
  text-align: center; }

p {
  line-height: 1.4;
  margin-bottom: 2rem;
  width: 100%; }

em {
  font-style: italic; }

sup {
  font-size: 80%;
  vertical-align: super; }

ul li {
  line-height: 1.4; }

strong,
.strong {
  font-weight: bold; }

small {
  font-size: 75%;
  line-height: 1.2; }

img {
  image-rendering: -webkit-optimize-contrast;
  width: 100%; }

.pull-right {
  float: right; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.lowercase {
  text-transform: lowercase; }

.trademark {
  font-family: "DM Serif Display", serif;
  font-weight: bold; }

.no-margin {
  margin: 0; }

.no-padding {
  padding: 0; }

.page {
  margin-top: 9rem;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.main {
  height: 100%; }

.descr {
  font-size: 1.8rem;
  line-height: 1.3;
  margin-bottom: 2rem;
  text-align: center; }
  .descr.short {
    width: 80%; }
  .descr a {
    text-decoration: underline; }

.loader {
  display: block;
  margin: 2rem 0;
  width: 100%; }

/******** FORM STYLES ***********/
form {
  text-align: left;
  width: 100%; }

.two-columns {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .two-columns > div {
    width: 49%; }
  .two-columns.ingredients ul li {
    padding: 0.2rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    .two-columns.ingredients ul li span {
      text-transform: lowercase; }
      .two-columns.ingredients ul li span:first-child {
        margin-right: 1rem;
        min-width: 7rem; }
      .two-columns.ingredients ul li span::first-letter,
      .two-columns.ingredients ul li span a,
      .two-columns.ingredients ul li span .link {
        text-transform: capitalize; }

.form-group {
  margin-bottom: 1.5rem; }

label {
  display: block;
  margin-bottom: 0.8rem; }

input,
select,
textarea,
.select {
  background-color: #ffffff;
  border: 1px solid #062139;
  border-radius: 0;
  box-shadow: none;
  color: #062139;
  font-family: "Merriweather Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  padding: 0 0.5rem;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  input::placeholder,
  select::placeholder,
  textarea::placeholder,
  .select::placeholder {
    color: #062139;
    opacity: 1;
    text-transform: uppercase; }

input,
textarea {
  padding: 0.4rem 0.5rem; }

.select {
  height: 2.6rem; }
  .select .select__control {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    height: 2.6rem;
    min-height: 0;
    width: 100%; }
    .select .select__control::after {
      content: '';
      background-image: url("assets/img/icon-arrow.svg");
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      height: 1.5rem;
      width: 1.5rem;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }
    .select .select__control.select__control--menu-is-open::after {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg); }
    .select .select__control:hover {
      border: 0;
      border-radius: 0;
      box-shadow: none; }
    .select .select__control .select__value-container {
      padding: 0; }
  .select .select__clear-indicator {
    padding: 0; }
  .select .select__indicator-separator,
  .select .select__dropdown-indicator {
    display: none; }
  .select .select__placeholder,
  .select .select__clear-indicator {
    color: #062139;
    opacity: 1;
    text-transform: uppercase; }
  .select .select__clear-indicator {
    cursor: pointer; }
  .select .select__menu {
    display: block !important;
    border: 0;
    border-radius: 0;
    left: 0;
    box-shadow: none;
    margin: 0;
    z-index: 2; }
  .select .select__menu-list {
    border: 1px solid #062139;
    color: #062139;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    padding: 0;
    text-align: left; }
  .select .select__option {
    padding: 0.5rem; }
    .select .select__option:hover {
      background-color: #f0e2b3; }
  .select .select__option:focus {
    background-color: #062139; }
  .select .select__option--is-focused,
  .select .select__option--is-selected {
    color: #062139;
    background-color: transparent; }

.input-big,
.select-big {
  border: 0;
  border-radius: 0;
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: -0.1rem;
  line-height: normal;
  margin: 0;
  padding: 0.5rem 1.5rem;
  text-transform: none;
  width: 100%; }
  .input-big::placeholder,
  .select-big::placeholder {
    color: #062139;
    opacity: 1;
    text-transform: none; }

.select-big {
  background-color: #ffffff;
  padding: 0; }
  .select-big .select__control {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    width: 100%; }
    .select-big .select__control:hover {
      border: 0;
      border-radius: 0;
      box-shadow: none; }
  .select-big .select__indicator-separator,
  .select-big .select__dropdown-indicator {
    display: none; }
  .select-big .select__placeholder,
  .select-big .select__clear-indicator {
    color: #062139;
    opacity: 1;
    white-space: nowrap; }
  .select-big .select__clear-indicator {
    cursor: pointer; }
  .select-big .select__menu {
    background-color: #f0e2b3;
    display: block !important;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    z-index: 1;
    left: 0; }
  .select-big .select__menu-list {
    color: #062139;
    font-family: "Open Sans", sans-serif;
    font-size: 1.6rem;
    text-align: left; }
  .select-big .select__option:focus {
    background-color: #062139; }
  .select-big .select__option--is-focused,
  .select-big .select__option--is-selected {
    color: #062139;
    background-color: #ffffff; }

.submit {
  margin-top: 5rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end; }

/******** TABLE ***********/
table {
  border: 2px solid #cdd3d7;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
  width: 100%; }
  table th {
    background-color: #cdd3d7;
    font-family: "Merriweather Sans", sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    padding: 0.5rem;
    text-transform: uppercase; }
  table td {
    background-color: #ffffff;
    border: 2px solid #cdd3d7;
    font-weight: 300;
    padding: 1rem; }
    table td p {
      display: block;
      margin: 0;
      text-align: left; }

/******** SLIDER ***********/
.slick-slider {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .slick-slider .slick-list {
    width: 100%; }
  .slick-slider .slick-slide {
    text-align: center; }
  .slick-slider .slick-prev,
  .slick-slider .slick-next {
    border: 0;
    font-size: 0;
    height: auto;
    line-height: 0;
    padding: 0; }
    .slick-slider .slick-prev::before,
    .slick-slider .slick-next::before {
      content: '';
      background-image: url("assets/img/icon-arrow.svg");
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      height: 3rem;
      width: 3rem; }
    .slick-slider .slick-prev:hover,
    .slick-slider .slick-next:hover {
      background: transparent;
      color: #062139;
      opacity: 0.5; }
  .slick-slider .slick-next {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }

/******** HERO BANNER STYLES ***********/
.hero-wrapper {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  height: 50rem;
  padding: 2% 4%;
  position: relative;
  text-align: center;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end; }
  .hero-wrapper h1,
  .hero-wrapper h2,
  .hero-wrapper .descr {
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.75); }
  .hero-wrapper .logo {
    width: 14rem; }
  .hero-wrapper .no-shadow {
    text-shadow: none; }
  .hero-wrapper .descr {
    color: #ffffff;
    font-weight: 700; }
  .hero-wrapper button,
  .hero-wrapper .button {
    color: #ffffff;
    border-color: #ffffff;
    display: inline-block; }
    .hero-wrapper button:hover,
    .hero-wrapper .button:hover {
      background: #ffffff;
      color: #062139; }
  .hero-wrapper .recipe-link {
    background-color: rgba(6, 33, 57, 0.3);
    color: #ffffff;
    font-style: italic;
    left: 0;
    padding: 1rem;
    position: absolute;
    top: 2vw;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    .hero-wrapper .recipe-link::after {
      content: '';
      background-image: url("assets/img/icon-arrow-white.svg");
      background-repeat: no-repeat;
      background-size: cover;
      display: inline-block;
      height: 1.5rem;
      margin-left: 0.5rem;
      width: 1.5rem;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .hero-wrapper .recipe-link:hover {
      color: rgba(255, 255, 255, 0.5); }
      .hero-wrapper .recipe-link:hover::after {
        opacity: 0.5; }

/******** BODY STYLES ***********/
.body-wrapper {
  padding: 2% 4%;
  position: relative;
  text-align: center;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .body-wrapper.column {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .body-wrapper.column .blocks {
      margin-bottom: 1rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .body-wrapper.primary {
    background-color: #062139;
    color: #ffffff; }
    .body-wrapper.primary button,
    .body-wrapper.primary .button {
      color: #ffffff;
      border-color: #ffffff;
      display: inline-block;
      margin: 2rem 0; }
      .body-wrapper.primary button:hover,
      .body-wrapper.primary .button:hover {
        background: #ffffff;
        color: #062139; }
  .body-wrapper.secondary {
    background-color: #cdd3d7; }
  .body-wrapper.pampas {
    background-color: #e9e9e8; }
  .body-wrapper.soft {
    background-color: #f0e2b3; }
  .body-wrapper .recipe-list {
    margin: 2rem 0;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .body-wrapper .related-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .body-wrapper .related-wrapper h3,
    .body-wrapper .related-wrapper .button {
      margin: 0 1rem; }
    .body-wrapper .related-wrapper h3 {
      max-width: 60%;
      text-align: left; }
    .body-wrapper .related-wrapper .block:nth-child(even) {
      background-color: #cdd3d7; }
    .body-wrapper .related-wrapper .recipe-list {
      -webkit-justify-content: flex-start;
      -moz-justify-content: flex-start;
      -ms-justify-content: flex-start;
      justify-content: flex-start;
      -ms-flex-pack: flex-start; }
  .body-wrapper .feedback-wrapper {
    margin: 4rem 0 2rem 0;
    width: 85%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between; }
    .body-wrapper .feedback-wrapper h3 {
      display: inline-block;
      margin: 0;
      margin-right: 0.5rem; }
  .body-wrapper .packages {
    margin: 2rem 0; }
  .body-wrapper .remark {
    bottom: 2%;
    font-size: 1.2rem;
    font-style: italic;
    line-height: normal;
    position: absolute;
    right: 4%; }

.block {
  background-color: #cdd3d7;
  cursor: pointer;
  height: 18rem;
  margin: 1rem;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 36rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .block:nth-child(even) {
    background-color: #e9e9e8; }
    .block:nth-child(even) .block-img span {
      color: #cdd3d7; }
  .block.recipe {
    background-color: #ffffff;
    height: 20rem;
    margin: 1%;
    width: 23%;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .block.recipe .block-img {
      height: 15rem;
      width: 100%; }
      .block.recipe .block-img img {
        height: auto;
        min-height: 15rem;
        width: 100%; }
    .block.recipe .block-txt {
      height: 5rem;
      padding: 0.5rem 1rem;
      width: 100%;
      -webkit-align-items: flex-start;
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start; }
      .block.recipe .block-txt .block-title {
        font-size: 1.8rem;
        padding: 0;
        text-overflow: ellipsis;
        word-wrap: break-word;
        height: 3.8rem;
        -webkit-line-clamp: 2;
        width: 100%; }
        .block.recipe .block-txt .block-title p {
          line-height: 1; }
          .block.recipe .block-txt .block-title p::first-letter {
            text-transform: uppercase; }
    .block.recipe:nth-child(even) {
      background-color: #ffffff; }
    .block.recipe.gray {
      background-color: #e9e9e8; }
      .block.recipe.gray:nth-child(even) {
        background-color: #e9e9e8; }
  .block .block-img {
    height: 18rem;
    overflow: hidden;
    width: 18rem;
    z-index: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .block .block-img img {
      height: 200%;
      width: 200%;
      object-fit: cover;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .block .block-img img.no-zoom {
        height: auto !important;
        width: 100% !important; }
    .block .block-img span {
      color: #e9e9e8;
      display: block;
      font-family: 'Oswald', sans-serif;
      font-size: 15rem;
      letter-spacing: -0.5rem; }
  .block .block-txt {
    height: 18rem;
    padding: 1.5rem;
    width: 18rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    .block .block-txt.category {
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center; }
    .block .block-txt .block-category {
      background-color: #ffffff;
      border: 1px solid #ffffff;
      color: #062139;
      font-family: "Merriweather Sans", sans-serif;
      font-size: 1.1rem;
      font-weight: 700;
      letter-spacing: 0.1rem;
      line-height: normal;
      overflow: hidden;
      padding: 0.3rem 0;
      position: absolute;
      top: 1.5rem;
      text-align: center;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      width: 42%;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .block .block-txt .block-title {
      color: #062139;
      display: -webkit-box;
      font-family: "DM Serif Display", serif;
      font-size: 2.8rem;
      letter-spacing: -0.1rem;
      overflow: hidden;
      padding: 0.5rem 0;
      word-break: break-word;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical; }
      .block .block-txt .block-title .small-font-size {
        font-size: 2.4rem; }
  .block:hover .block-img img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }
  .block:hover .block-txt .block-title {
    color: #062139; }
  .block:hover .block-txt .block-category {
    background-color: transparent;
    border-color: #062139; }
  .block.short {
    width: 18rem; }
    .block.short .block-txt .block-title {
      padding: 0.3rem 0; }
  .block.flip {
    background-color: transparent;
    overflow: visible;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -o-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000; }
    .block.flip:hover .back {
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      transform: rotateY(0deg); }
    .block.flip:hover .front {
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      -o-transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      transform: rotateY(180deg); }
    .block.flip .flip-inner {
      position: relative;
      width: 100%;
      height: 100%;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -o-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transform: perspective(1000px);
      -moz-transform: perspective(1000px);
      -o-transform: perspective(1000px);
      -ms-transform: perspective(1000px);
      transform: perspective(1000px); }
      .block.flip .flip-inner .front .block-txt {
        padding-right: 2rem; }
    .block.flip .front,
    .block.flip .back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -o-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -o-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .block.flip .front {
      background-color: #cdd3d7;
      z-index: 2;
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      transform: rotateY(0deg);
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
    .block.flip .back {
      background-color: #f0e2b3;
      padding: 1.5rem;
      padding-top: 3rem;
      -webkit-transform: rotateY(-180deg);
      -moz-transform: rotateY(-180deg);
      -o-transform: rotateY(-180deg);
      -ms-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .block.flip .back span {
        display: block;
        width: 100%; }
      .block.flip .back.image {
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center; }
        .block.flip .back.image img {
          height: 180%;
          margin-bottom: 4rem;
          object-fit: cover;
          width: 180%; }
      .block.flip .back.dark, .block.flip .back.light {
        background-color: #cdd3d7;
        font-size: 1.2rem;
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        -ms-justify-content: flex-start;
        justify-content: flex-start;
        -ms-flex-pack: flex-start; }
        .block.flip .back.dark ul, .block.flip .back.light ul {
          list-style: disc;
          list-style-position: outside;
          padding-left: 2rem; }
      .block.flip .back.light {
        background-color: #e9e9e8; }
    .block.flip:nth-child(even) .front {
      background-color: #e9e9e8; }

.flavor-item {
  color: #062139;
  margin: 1rem 0;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .flavor-item img {
    display: block;
    width: 12rem; }
  .flavor-item span {
    font-family: "Merriweather Sans", sans-serif;
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
    padding-top: 1rem;
    text-transform: uppercase;
    width: 100%; }
  .flavor-item .block-info {
    background-color: #ffffff;
    height: 28rem;
    visibility: hidden;
    opacity: 0;
    padding-bottom: 1rem;
    position: absolute;
    bottom: -5rem;
    left: 0;
    z-index: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .flavor-item:hover {
    z-index: 1; }
    .flavor-item:hover img,
    .flavor-item:hover span {
      z-index: 2; }
    .flavor-item:hover .block-info {
      visibility: visible;
      opacity: 1; }

.feature-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .feature-wrapper .feature-content {
    width: 50%; }
  .feature-wrapper .feature-block {
    margin: 4rem auto 1rem auto;
    position: relative;
    width: 97%; }
    .feature-wrapper .feature-block img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .feature-wrapper .feature-block span {
      color: #ffffff;
      bottom: 2%;
      font-family: "DM Serif Display", serif;
      font-size: 12rem;
      left: 2%;
      position: absolute;
      text-shadow: 6px 6px 15px rgba(0, 0, 0, 0.4); }
    .feature-wrapper .feature-block .percent-circle {
      background-color: #062139;
      border-radius: 50%;
      color: #ffffff;
      font-family: "DM Serif Display", serif;
      font-size: 5rem;
      height: 15rem;
      padding: 0 3rem;
      position: relative;
      width: 15rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center; }
      .feature-wrapper .feature-block .percent-circle::after {
        border: 1px dotted #062139;
        bottom: -5vw;
        left: 49%;
        content: '';
        height: 6vw;
        display: block;
        position: absolute; }
    .feature-wrapper .feature-block:last-child .percent-circle::after {
      content: none; }
  .feature-wrapper .descr {
    margin: 0 auto;
    margin-bottom: 2rem;
    text-align: left;
    width: 90%; }
  .feature-wrapper.single .feature-block {
    margin-top: 0;
    width: 80vw; }
    .feature-wrapper.single .feature-block span {
      font-size: 16rem; }
  .feature-wrapper.single .descr {
    text-align: center; }

.taste-wrapper {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .taste-wrapper .taste-block {
    margin: 1rem;
    width: 28vw;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .taste-wrapper .taste-block .taste-img {
      margin-bottom: 1rem;
      overflow: hidden;
      position: relative;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center; }
      .taste-wrapper .taste-block .taste-img h2 {
        color: #ffffff;
        font-size: 4vw;
        margin: 0;
        position: absolute;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.6); }
    .taste-wrapper .taste-block a {
      text-decoration: underline; }
    .taste-wrapper .taste-block .taste-content {
      position: relative;
      z-index: 1;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
      .taste-wrapper .taste-block .taste-content p {
        min-height: 17vw;
        text-align: left;
        width: 15vw; }
      .taste-wrapper .taste-block .taste-content img {
        height: 23vw;
        position: absolute;
        right: -2vw;
        top: -5vw;
        width: auto; }

.benefits-wrapper {
  text-align: left;
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }
  .benefits-wrapper img {
    height: 100%; }
  .benefits-wrapper .descr {
    margin: 0 2%;
    text-align: center;
    width: 23%; }
    .benefits-wrapper .descr span {
      border-bottom: 2px solid #062139;
      display: block;
      font-family: "DM Serif Display", serif;
      font-size: 7rem;
      line-height: 1.2;
      margin-bottom: 1rem; }
  .benefits-wrapper .img-block {
    text-align: center;
    width: 46%; }
    .benefits-wrapper .img-block span {
      display: block;
      font-size: 1.2rem;
      font-weight: 700;
      letter-spacing: 0.1rem;
      margin: 0 auto;
      text-transform: uppercase;
      width: 60%; }
    .benefits-wrapper .img-block img {
      width: 100%; }

.attachment-error {
  font-size: 1.4rem;
  color: #990000;
  text-align: center; }

.disclaimer {
  background-color: #062139;
  color: #fff;
  display: none;
  font-size: 1.4rem;
  padding: 1.5rem;
  position: fixed;
  bottom: 0;
  left: auto;
  right: auto;
  width: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .disclaimer span {
    margin-right: 1rem; }
  .disclaimer button {
    background-color: #fff;
    color: #062139;
    margin-left: 2rem;
    min-width: 10rem; }
    .disclaimer button:focus, .disclaimer button:hover {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
  @media screen and (max-width: 63rem) {
    .disclaimer {
      padding: 1rem; } }

/******** FILTERS ***********/
/* Fonts */
/* Colors */
/* Mixins */
.filters {
  font-family: "DM Serif Display", serif;
  font-size: 3rem;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .filters > div {
    font-size: 3rem;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .filters > div .select-big {
      margin: 1rem;
      width: 18%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      background-color: transparent; }
  .filters .select__single-value {
    overflow: visible; }
  .filters .select__value-container--has-value {
    top: 0;
    padding: 5.5rem 0 0;
    position: absolute;
    font-family: "Open Sans", sans-serif;
    font-size: 1.6rem; }
    .filters .select__value-container--has-value .select__multi-value {
      background-color: #fff; }
  .filters .select__value-container .select__input {
    display: block !important;
    margin-top: -4px;
    font-size: 1.6rem; }
    .filters .select__value-container .select__input input {
      text-transform: none; }
  .filters .select__clear-indicator {
    display: none; }
  .filters .select-wraper {
    position: relative;
    margin: 1rem .5rem;
    width: 18%;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    color: #062139; }
    .filters .select-wraper .title {
      position: absolute;
      width: 100%;
      padding: 1rem;
      background-color: #fff;
      text-align: left; }
      .filters .select-wraper .title .hidden {
        opacity: 0; }
    .filters .select-wraper .select-big {
      position: absolute;
      margin: 0;
      width: 100%;
      z-index: 2; }
      .filters .select-wraper .select-big .select__control {
        min-height: 5rem; }
      .filters .select-wraper .select-big .select__menu-list {
        max-height: 40rem; }
        .filters .select-wraper .select-big .select__menu-list::-webkit-scrollbar {
          width: 1rem; }
        .filters .select-wraper .select-big .select__menu-list::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
        .filters .select-wraper .select-big .select__menu-list::-webkit-scrollbar-thumb {
          background-color: #816e3a; }
    .filters .select-wraper .values {
      margin-top: 5.5rem;
      word-break: break-word;
      opacity: 0; }
      .filters .select-wraper .values span {
        margin: 2px;
        padding: 5px 3px;
        font-size: 52%;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box; }
    .filters .select-wraper.mobile {
      width: 100%; }
      .filters .select-wraper.mobile .title {
        padding: 1.5rem 1rem;
        font-size: 65%; }
      .filters .select-wraper.mobile .select__indicators {
        position: absolute;
        right: 0; }

.body-wrapper.recipes .filters .select-wraper {
  width: 28%; }
  .body-wrapper.recipes .filters .select-wraper.mobile {
    width: 100%; }

.body-wrapper.recipes .filters > div .select-big {
  width: 100%; }

@media screen and (max-width: 767px) {
  .body-wrapper.recipes .filters > div .select-big {
    width: 100%; }
  .filters > div {
    display: block; }
  .filters .select__group-heading {
    color: #062139;
    font-family: "Open Sans", sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: capitalize; } }

/******** MEDIA QUERIES ***********/
@media screen and (min-width: 1241px) {
  .block .block-txt.bottom-title {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end; } }

@media screen and (min-width: 1600px) {
  .block.recipe {
    width: 30rem; } }

@media screen and (max-width: 1240px) {
  .block {
    height: 22vw;
    margin: 1vw;
    width: 44vw; }
    .block .block-img,
    .block .block-txt {
      height: 22vw;
      width: 22vw; }
    .block.short {
      height: 18rem;
      width: 18rem; }
      .block.short .block-img,
      .block.short .block-txt {
        height: 18rem;
        width: 18rem; }
  .feature-wrapper .feature-block span {
    font-size: 10vw; } }

@media screen and (max-width: 1000px) {
  .page {
    margin-top: 7rem; }
  .button-group {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .button-group button,
    .button-group .button {
      margin: 0.5rem; }
  .hero-wrapper .recipe-link {
    bottom: 0;
    left: auto;
    top: auto;
    right: 0;
    padding: 1.5rem 3rem 1.5rem; }
  .body-wrapper .related-wrapper h3 {
    max-width: 50%; }
  .block .block-txt .block-title {
    line-height: 3rem;
    padding: 0;
    -webkit-line-clamp: 3; }
  .feature-wrapper.single .feature-block {
    width: 100%; }
    .feature-wrapper.single .feature-block span {
      font-size: 20vw; }
  .benefits-wrapper {
    padding: 0; } }

@media screen and (max-width: 767px) {
  h1 {
    font-size: 3.5rem;
    letter-spacing: -0.1rem; }
  h2 {
    font-size: 3rem;
    margin: 1rem 0; }
  h3 {
    font-size: 2.5rem; }
  .descr {
    font-size: 1.4rem; }
    .descr br {
      display: none; }
  .descr.short {
    width: 100%; }
  .input-big {
    font-size: 2rem;
    height: 3.5rem; }
  .select-big {
    font-size: 2rem; }
    .select-big .select__menu {
      background-color: #f0e2b3; }
    .select-big .select__dropdown-indicator {
      color: #062139;
      display: block;
      padding-top: 13px; }
    .select-big .select__option:focus {
      background-color: #062139; }
    .select-big .select__option--is-focused,
    .select-big .select__option--is-selected {
      color: #062139;
      background-color: #ffffff; }
  .submit {
    margin-top: 3rem;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .hero-wrapper {
    height: auto;
    padding: 4%; }
    .hero-wrapper .logo {
      width: 10rem; }
    .hero-wrapper .recipe-link {
      right: auto;
      width: 100%;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center; }
  .block .block-txt .block-title {
    font-size: 2.5rem;
    line-height: 2.8rem;
    padding: 0.1rem 0;
    -webkit-line-clamp: 3; }
  .block.recipe {
    height: 13rem;
    margin: 2%;
    width: 46%; }
    .block.recipe .block-img {
      height: 8rem; }
      .block.recipe .block-img img {
        min-height: 8rem; }
    .block.recipe .block-txt {
      height: 5rem; }
      .block.recipe .block-txt .block-title {
        -webkit-line-clamp: 2; }
  .block.flip .back {
    padding: 1rem; }
  .body-wrapper {
    padding: 4%; }
    .body-wrapper .related-wrapper {
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center; }
      .body-wrapper .related-wrapper h3 {
        font-size: 2.1rem;
        margin: 0;
        max-width: none;
        order: 1;
        position: relative;
        text-align: center; }
      .body-wrapper .related-wrapper .recipe-list {
        order: 2; }
      .body-wrapper .related-wrapper .button {
        margin: 0 0.5rem;
        order: 3;
        position: relative; }
    .body-wrapper .feedback-wrapper {
      display: block;
      font-size: 1.4rem;
      text-align: center;
      width: 100%; }
      .body-wrapper .feedback-wrapper h3,
      .body-wrapper .feedback-wrapper .button {
        display: block;
        margin: 1rem 0; }
    .body-wrapper .remark {
      bottom: auto;
      position: relative;
      right: auto; }
  .two-columns {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .two-columns > div {
      margin-bottom: 2rem;
      width: 100%; }
  .feature-wrapper {
    display: block; }
    .feature-wrapper .feature-content {
      width: 100% !important; }
    .feature-wrapper .feature-block {
      margin: 1rem auto;
      height: 20rem;
      width: 100%; }
      .feature-wrapper .feature-block span {
        font-size: 20vw; }
      .feature-wrapper .feature-block .percent-circle {
        height: 13rem;
        width: 13rem; }
        .feature-wrapper .feature-block .percent-circle::after {
          bottom: -5rem;
          height: 5rem; }
    .feature-wrapper .descr {
      text-align: center;
      width: 100%; }
  .taste-wrapper .taste-block {
    width: 100%; }
    .taste-wrapper .taste-block .taste-img {
      height: 15rem; }
      .taste-wrapper .taste-block .taste-img h2 {
        font-size: 3rem; }
    .taste-wrapper .taste-block .taste-content p {
      min-height: 0;
      width: 75%; }
    .taste-wrapper .taste-block .taste-content img {
      height: auto;
      right: -2rem;
      top: -10rem;
      width: 30%; }
  .benefits-wrapper {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .benefits-wrapper img {
      width: 50%; }
    .benefits-wrapper .descr {
      order: 1;
      width: 70%; }
    .benefits-wrapper .img-block {
      order: 2;
      margin: 5rem 0;
      width: 100%; } }

@media screen and (max-width: 640px) {
  .block {
    height: 45vw;
    width: 90vw; }
    .block .block-img,
    .block .block-txt {
      height: 45vw;
      width: 45vw; }
    .block.short {
      height: 40vw;
      width: 40vw; }
      .block.short .block-img,
      .block.short .block-txt {
        height: 40vw;
        width: 40vw; }
      .block.short .block-txt {
        padding: 1rem; }
    .block.flip .flip-inner .front .block-txt {
      padding: 1rem; } }

@media screen and (max-width: 320px) {
  .block .block-txt .block-title {
    padding: 0.3rem 0;
    -webkit-line-clamp: 2; } }
