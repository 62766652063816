@import '../../variables';

.hero-wrapper.wings {
  background-image: url('../../assets/img/hero-wings.jpg');
}

.body-wrapper.wings {
  .feature-wrapper {
    img {
      height: 100%;
      margin-left: -15vw;
      margin-top: -5vw;
      width: 75%;
    }

    .feature-block {
      margin: 0;
      margin-bottom: 10%;
      text-align: left;
      width: 100%;
      @include flexbox();
      @include align-items(center);

      &:last-child .percent-circle::after {
        content: none;
      }

      .descr {
        margin: 0;
        margin-left: 4%;
      }
    }
  }

  .benefits-wrapper {
    .img-block span {
      width: 50%;
    }

    &:last-child .img-block img {
      width: 80%;
    }
  }

  .flavor-item img {
    width: 20rem;
  }
}

@media screen and (max-width: 1000px) {
  .body-wrapper.wings {
    h2 br {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.wings {
    height: 20rem;
  }

  .body-wrapper.wings {
    .descr {
      font-size: 1.6rem;
    }

    .feature-wrapper {
      img {
        display: none;
      }

      .feature-block {
        height: auto;
        margin: 0;
        margin-bottom: 5rem;

        .descr {
          text-align: left;
        }
      }
    }
  }
}
