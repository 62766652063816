@import '../../variables';

.hero-wrapper.stadium {
  background-image: url('../../assets/img/offers/stadium-staples/top-image.jpg');
  text-align: left;
  @include align-items(flex-start);
  @include justify-content(center);

  h1 {
    width: 50vw;
  }

  .descr {
    text-align: left;
    width: 40vw;
  }
}

.body-wrapper.stadium {
  .benefits-wrapper {
    img {
      width: 12%;
    }
  }

  .remark {
    bottom: 0;
    margin: 4rem 0;
    position: relative;
    right: 0;
  }
}

@media screen and (max-width: 1000px) {
  .hero-wrapper.stadium {
    padding: 4%;

    h1,
    .descr {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .body-wrapper.stadium {
    .benefits-wrapper {
      img {
        width: 40%;
      }

      .descr {
        margin-bottom: 2rem;
        order: inherit;
      }
    }

    .remark {
      margin: 2rem 0;
    }
  }
}
