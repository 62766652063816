@import '../../variables';

.hero-wrapper.green-goddess {
  background-image: url('../../assets/img/offers/green-goddess/top-image.jpg');
  image-rendering: -webkit-optimize-contrast;
  @include align-items(center);
  @include justify-content(center);

  h1 {
    font-size: 6rem;
  }

  .logo {
    position: absolute;
    left: 4%;
    top: 6%;
    width: 10rem;
  }
}

.body-wrapper.green-goddess {
  h2 {
    font-size: 4.5rem;
    letter-spacing: -0.2rem;
  }

  h4 {
    font-family: $font-serif;
    font-size: 2.3rem;
    letter-spacing: -0.1rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.8rem;
    line-height: 1.3;
  }

  .descr {
    width: 80%;

    &.short {
      width: 60%;
    }
  }

  .product-intro {
    max-width: 110rem;
    text-align: left;
    width: 100%;
    @include flexbox;
    @include justify-content(center);

    p {
      width: 90%;
    }

    img {
      height: 50rem;
      margin-top: -13rem;
      width: auto;
      z-index: 1;
    }

    .button-group {
      @include justify-content(flex-start);
    }
  }

  .feature-wrapper {
    .feature-block {
      margin-top: 1rem;

      span {
        font-size: 7vw;
      }
    }

    .descr {
      font-size: 1.6rem;
      margin: 0 0.5rem 2rem 0.5rem;
      width: 90%;
    }
  }

  .recipe-wrapper {
    max-width: 115rem;
    width: 100%;
    @include flexbox;
    @include justify-content(center);
    @include flex-wrap(wrap);

    .recipe-item {
      margin-top: 2rem;
      position: relative;
      width: 50%;

      span {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.3;
        left: 0;
        position: absolute;
        top: 0;
        text-align: left;
        width: 15rem;
      }

      img {
        height: 40rem;
        width: auto;
      }

      &:nth-child(2) {
        margin-left: -3rem;
        margin-top: 7rem;

        span {
          left: 4rem;
          top: -4rem;
        }
      }

      &:nth-child(3) {
        margin-left: -10rem;
        margin-top: -10rem;

        span {
          top: 26rem;
          left: -5rem;
        }
      }
    }
  }

  .product-wrapper {
    @include flexbox;
    @include justify-content(center);
    @include flex-wrap(wrap);

    img {
      height: 100%;
      max-width: 30rem;
      width: 100%;
      object-fit: cover;
    }
  }

  table {
    font-size: 1.6rem;
    margin: 3rem 0;

    tr:nth-child(even) td {
      background-color: $color-pampas;
    }

    td:last-child {
      font-weight: 700;
    }
  }

  .make-it-wrapper {
    margin-bottom: 2rem;
    text-align: left;
    @include flexbox;
    @include justify-content(center);
    @include flex-wrap(wrap);

    .make-it-item {
      width: 32rem;
      @include flexbox;
      @include align-items(center);
      @include flex-direction(column);

      p {
        margin-bottom: 1rem;
        max-width: 24rem;
      }

      .make-it-imgs {
        font-size: 6rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);

        img {
          height: 18rem;
          width: auto;
        }
      }
    }
  }

  .feedback-wrapper {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 1024px) {
  .hero-wrapper.green-goddess {
    height: auto;
    min-height: 35rem;
    padding: 4%;
  }

  .body-wrapper.green-goddess {
    .descr.short {
      width: 100%;
    }

    .product-intro {
      br {
        display: none;
      }
    }

    .recipe-wrapper {
      max-width: none;

      .recipe-item {
        width: 33%;
        @include flexbox;
        @include align-items(center);
        @include flex-direction(column-reverse);
        @include flex-wrap(wrap);
        @include justify-content(flex-end);

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-left: 0;
          margin-top: 0;

          img {
            height: auto;
            width: 100%;
          }

          span {
            position: relative;
            bottom: auto;
            top: auto;
            left: auto;
            right: auto;
            text-align: center;
            width: auto;
          }
        }
      }
    }

    .make-it-wrapper {
      width: 100%;

      .make-it-item {
        width: 33%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.green-goddess {
    min-height: 25rem;

    h1 {
      font-size: 3.5rem;
    }

    .logo {
      left: auto;
      right: 4%;
    }
  }

  .body-wrapper.green-goddess {
    h2 {
        font-size: 3rem;
        letter-spacing: -0.1rem;
    }

    h4 {
      font-size: 2rem;
    }

    .descr{
      width: 100%;
    }

    p {
      font-size: 1.4rem;
    }

    .feature-wrapper {
      .descr {
        font-size: 1.4rem;
        margin: 0 auto;
        margin-bottom: 2rem;
        width: 100%;
      }

      .feature-block span {
        font-size: 20vw;
      }
    }

    .product-intro {
      display: block;
      text-align: center;

      img {
        height: 20rem;
        margin-top: -6rem;
      }

      .button-group {
        @include justify-content(center);
      }
    }

    .recipe-wrapper {
      display: block;

      .recipe-item {
        margin-bottom: 1rem;
        width: 100%;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          span {
            font-size: 1.6rem;
            top: -2rem;
          }
        }
      }
    }

    .product-wrapper img {
      width: 20rem;
    }

    .make-it-wrapper .make-it-item {
      display: block;
      margin-bottom: 2rem;
      width: 100%;

      p {
        max-width: none;
        text-align: center;
      }
    }
  }
}
