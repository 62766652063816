@import '../../variables';

.hero-wrapper.products {
  background-image: url('../../assets/img/hero-products.jpg');
  padding-top: 6%;

  .button {
    background-color: $color-white;
    bottom: 4vw;
    color: $color-primary;
    position: absolute;
    right: 3vw;

    &:hover {
      background-color: $color-link;
      border-color: $color-link;
      color: $color-white;
    }
  }
}

.body-wrapper.products {
  text-align: center;
  @include justify-content(center);
  @include flex-direction(column);

  .flavor-list {
    margin: 1rem 0;
    width: 100%; //IE fix
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);

    .flavor-item {
      line-height: 1.4;
      width: 15rem;
    }
  }

  &.light {
    background-color: $color-pampas;
    color: $color-primary;
    @include flex-direction(column);
    @include justify-content(flex-start);
  }

  &.single {
    margin: auto;
    text-align: left;
    width: 100%;

    .modal-body {
      .slick-slider .slick-slide img {
        height: 40rem;
        margin: 0 auto;
        width: auto;
      }

      .download-fields {
        margin-bottom: 3rem;
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap);

        .form-group {
          @include flexbox();
          @include flex-direction(column);
          @include align-items(center);
          @include justify-content(flex-end);

          img {
            height: 10vw;
            width: auto;
          }

          .react-pdf__Page {
            width: 8vw;

            .react-pdf__Page__canvas {
              height: 10vw !important;
              margin: 0 auto;
              width: auto !important;
            }

            .react-pdf__Page__textContent,
            .react-pdf__Page__annotations {
              display: none !important;
            }
          }

          .checkbox {
            margin-top: 1rem;
            padding: 0;

            .checkmark {
              display: block;
              position: relative;
            }
          }
        }
      }
    }

    .nutrition-wrapper {
      background-color: $color-white;
      border: 1px solid $color-primary;
      color: $color-primary;
      font-family: Helvetica, Arial, sans-serif;
      margin: 0 auto;
      max-width: 25rem;
      padding: 1rem;
      text-align: left;
      width: 100%;

      h2 {
        border-bottom: 2px solid $color-primary;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 3.2rem;
        font-weight: bold;
        letter-spacing: normal;
        margin: 0;
        text-align: left;
      }

      ul {
        font-size: 1.6rem;
        font-weight: 300;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          border-bottom: 1px solid $color-primary;
          padding: 2px 0;
        }

        .med {
          border-bottom-width: 0.4rem;
          padding-top: 0;

          .med-text {
            font-size: 2.8rem;
            line-height: 2.8rem;
          }

          .big-text {
            font-size: 4.4rem;
            line-height: 2rem;
          }
        }

        .lg {
          border-bottom-width: 1rem;
          font-size: 2rem;

          span {
            font-size: 1.8rem;
            line-height: 2.8rem;
          }
        }

        .no-border {
          border-bottom: 0;
          padding-bottom: 0;
        }

        .indent {
          margin-left: 2rem;
        }
      }
    }

    .banner-new {
      background-color: $color-primary;
      color: $color-white;
      display: block;
      padding: 2rem;
      position: relative;
      text-align: center;
      width: 24vw;

      &::after {
        border-bottom: 2rem solid $color-white;
        border-left: 12vw solid transparent;
        border-right: 12vw solid transparent;
        content: '';
        height: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
      }
    }

    .product-desktop-wrapper {
      width: 100%; // IE fix

      .button-group {
        margin: 2rem 0;
        @include justify-content(flex-start);

        button,
        .button {
          margin: 0.3rem;
          padding: 0.5rem;
        }
      }
    }

    .product-mobile-wrapper {
      display: none;
      width: 100%;

      .banner-new {
        width: 80vw;
  
        &::after {
          border-bottom: 2rem solid $color-white;
          border-left: 40vw solid transparent;
          border-right: 40vw solid transparent;
        }
      }
    }

    .two-columns {
      @include align-items(stretch);

      .aside {
        width: 30%;
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);

        .product-img {
          min-height: 40rem;
          width: auto;
          @include flexbox();
          @include align-items(flex-end);
          @include justify-content(center);
          &.hide {
            display: none;
          }
        }

        .block-info {
          display: block;
          margin: 2rem 0;
        }

        p {
          font-size: 1.3rem;
        }
      }

      .product-intro {
        width: 68%;

        h3 {
          margin-top: 0;
          text-align: center;
        }

        .product-descr {
          font-size: 1.6rem;
        }

        table {
          margin-bottom: 2rem;

          td::first-letter {
            text-transform: capitalize;
          }

          &.no-margin {
            margin: 0;
          }
        }

        .packages img {
          max-width: 10rem;
        }
      }

      .product-disclaimer h3 {
        text-align: left;
      }

      &.new {
        @include align-items(flex-start);

        & > div {
          width: 40%;

          &:first-child {
            margin-right: 2%;
            width: 58%;
          }
        }
      }
    }

    .block-info {
      height: auto;
      @include align-items(center);

      .marketing-claims {
        font-size: 1.2rem;
        margin-right: 2rem;
        width: 20rem;

        & > div {
          padding: 0.5rem;
        }
      }

      .allergens {
        margin: 1rem 0;
        @include flex(1 1 35rem);
      }

      .ingredients-icons {
        text-align: center;
        @include flexbox();
        @include flex-wrap(wrap);

        img {
          height: auto;
          margin: 1rem 0.5rem;
        }
      }
    }

    .product-disclaimer {
      border-top: 1px solid $color-primary;
      padding-top: 1rem;
    }
  }

  .select__menu {
    .select__group {
      & > div {
        display: none;
      }

      .select__group-heading {
        display: block;

        & > div {
          display: block;
          position: relative;

          &::after {
            content: '';
            background-image: url("../../assets/img/icon-arrow.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            height: 1.5rem;
            position: absolute;
            right: 0;
            top: 15%;
            width: 1.5rem;
            @include transform(rotate(-90deg));
          }

          &.active::after {
            @include transform(rotate(90deg));
          }
        }
      }

      .select__option {
        position: relative;
        padding: .8rem 1.2rem .8rem 3.2rem;

        &::before {
          content: '';
          display: block;
          position: absolute;
          height: 1.2rem;
          width: 1.2rem;
          top: 1.2rem;
          left: 1.2rem;
          border: 1px solid black;
        }

        &.select__option--is-selected {
          background-color: transparent;
          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 1.5rem;
            top: 1rem;
            width: .5rem;
            height: 1rem;
            border: solid #000;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &::before {
            background: #fff;
          }
        }
      }
    }
  }

  &.secondary {
    .packages {
      height: 100%; //IE fix
      width: 100%; //IE fix

      img {
        width: 23%;
      }

      a img {
        width: 15%;

        &[data-type="bar bottles"] {
          width: 10%;
        }
      }
    }

    h2 {
      width: 100%; //IE fix
    }
  }
}

.block-info {
  font-size: 1.1rem;
  font-weight: 700;
  height: 100%;
  letter-spacing: 0.1rem;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  @include flexbox();
  @include flex-wrap(wrap);
  width: 100%;

  img {
    height: 100%; // IE fix
    width: 6rem;
  }

  .marketing-claims {
    font-family: $font-sans-serif-bold;
    width: 100%;

    & > div {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);

      span {
        @include flexbox();
        @include align-items(center);
      }
    }

    .kosher span span {
      margin: 0 .25rem;
    }

    img {
      top: -1px;
      position: relative;
      margin-left: 0.3rem;
      width: 1.5rem;
    }
  }
}

.product-list {
  margin: 2rem 0;
  width: 100%; // IE fix

  & > div {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
  }

  .product-item {
    background-color: $color-white;
    color: $color-primary;
    width: 28rem;
    margin: 0.5rem;
    padding: 1rem;
    position: relative;
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);

    & > div {
      height: 100%;
      width: 100%;
      @include flexbox();
      @include align-items(center);
    }

    .product-img-container {
      span {
        font-family: $font-sans-serif-bold;
        font-size: 1.2rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
        margin-bottom: 2rem;
        text-transform: uppercase;
      }
    }

    .product-img {
      width: 12rem;
    }

    .block-info h3 {
      font-family: $font-sans-serif;
      font-size: 2rem;
      font-weight: 300;
      text-transform: capitalize;
    }

    .marketing-claims,
    .allergens {
      opacity: 0;
      visibility: hidden;
      @include transition;
    }

    .marketing-claims {
      font-size: .95rem;
    }

    .allergens {
      background-color: $color-pampas;
      height: auto;
      padding: 1rem 0;
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 1;
      @include transition;
      @include justify-content(center);
      @include flex-wrap(wrap);

      img {
        width: 4.5rem;
      }
    }

    .additional-info {
      width: 100%;

      div {
        padding: 0 0 .2rem;
      }
    }

    &.new {
      .product-code::after,
      .product-gtin::after,
      .block-info::after {
        background-color: $color-primary;
        color: $color-white;
        content: 'new';
        display: inline-block;
        font-size: 0.8rem;
        margin-left: 0.25rem;
        padding: 0.3rem;
        text-align: center;
      }

      .block-info::after {
        content: 'new product code and gtin';
        letter-spacing: 0;
        padding: 0.5rem;
        position: absolute;
        right: 0;
        bottom: 0;
        @include transition;
      }

      &:hover {
        .block-info::after {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    &:hover {
      background-color: $color-pampas;

      .allergens,
      .marketing-claims {
        opacity: 1;
        visibility: visible;
      }
    }

    
  }
}

.tabs-list {
  font-family: $font-sans-serif-bold;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  @include flexbox();
  @include justify-content(flex-start);

  li {
    cursor: pointer;
    text-align: center;
    width: 10rem;
    @include align-self(baseline);
    @include transition;

    &:hover,
    &.active {
      color: $color-link;
    }
  }
}

@media screen and (min-width: 768px) {
  .body-wrapper.products.single {
    .two-columns {
      .aside {
        width: 25%;

        .product-img {
          img {
            width: 27rem;
          }

          &.package-cup img {
            width: 27rem;
          }

          &.package-bag-in-box,
          &.package-tub,
          &.package-pouch {
            img {
              width: 23rem;
            }
          }

          &.package-pillow,
          &.package-dispenser {
            @include align-items(center);
          }
        }
      }

      .product-intro {
        width: 70%;
      }
    }

    .nutrition-wrapper {
      h2 {
        font-size: 2.1rem;
      }

      ul {
        font-size: 1.1rem;

        .med {
          padding-top: 1rem;

          .med-text {
            font-size: 2rem;
            line-height: 2rem;
          }

          .big-text {
            font-size: 3rem;
            line-height: 1.4rem;
          }
        }

        .lg {
          font-size: 1.2rem;

          span {
            font-size: 1.1rem;
            line-height: 2.2rem;
          }
        }
      }
    }
  }

  .tabs-list {
    .package-bar.bottle img {
      margin-bottom: .2rem;
    }

    .package-cup img {
      margin-bottom: -.2rem;
    }

    .package-pillow,
    .package-dispenser {
      width: 14rem;

      img {
        height: 14rem;
        margin-bottom: -.5rem;
        width: auto;
      }
    }

    .package-tub img {
      margin-bottom: -.5rem;
    }

    .package-bag-in-box img {
      margin-bottom: -1.5rem;
    }
  }
}

@media screen and (min-width: 1024px) {
  .body-wrapper.products.single {
    margin: 0;
    padding: 2% 10rem;

    .nutrition-wrapper,
    .ingredients-wrapper {
      max-width: 25rem;
    }

    .nutrition-wrapper {
      h2 {
        font-size: 2.5rem;
      }

      ul {
        font-size: 1.2rem;

        .med {
          .med-text {
            font-size: 2.2rem;
            line-height: 2rem;
          }

          .big-text {
            font-size: 3rem;
            line-height: 1.4rem;
          }
        }

        .lg {
          font-size: 1.4rem;

          span {
            font-size: 1.3rem;
            line-height: 2.2rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .body-wrapper.products.single {
    .two-columns {
      .aside {
        width: 27%;

        .product-img {
          min-height: 48rem;

          img {
            width: 37rem;
          }

          &.package-cup,
          &.package-tub,
          &.package-pouch {
            img {
              width: 37rem;
            }
          }

          &.package-bag-in-box {
            img {
              width: 33rem;
            }
          }
        }
      }
    }

    .nutrition-wrapper,
    .ingredients-wrapper {
      max-width: 30rem;
    }

    .nutrition-wrapper {
      h2 {
        font-size: 3.8rem;
      }

      ul {
        font-size: 1.6rem;

        .med {
          .med-text {
            font-size: 2.8rem;
            line-height: 2.8rem;
          }

          .big-text {
            font-size: 4.4rem;
            line-height: 2rem;
          }
        }

        .lg {
          font-size: 2rem;

          span {
            font-size: 1.8rem;
            line-height: 2.8rem;
          }
        }
      }
    }

    .product-desktop-wrapper .button-group {
      @include justify-content(space-between);
      @include flex-wrap(nowrap); // IE fix

      button,
      .button {
        @include flex(1 1 auto);
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .body-wrapper.products.single .two-columns .aside .product-img {
    &.package-cup img {
      min-width: 35rem;
      width: 100%;
    }

    &.package-bag-in-box,
    &.package-tub,
    &.package-pouch {
      img {
        width: 30rem;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .hero-wrapper.products {
    padding-top: 2%;
    padding-bottom: 15%;

    .button {
      bottom: auto;
      position: relative;
      right: auto;
    }
  }

  .body-wrapper.products.single {
    .modal-body {
      .slick-slider .slick-slide img {
        height: auto;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .body-wrapper.products {
    &.flavor-section {
      h2 {
        order: 1;
      }

      h3 {
        order: 2;
      }

      .descr {
        margin-bottom: 0;
        order: 3;
      }

      .flavor-list {
        order: 4;
      }
    }

    &.single {
      h2 {
        font-size: 2.5rem;
      }

      .modal-body {
        .modal-content h3 {
          font-size: 7vw;
        }

        .download-fields {
          @include justify-content(center);

          .form-group {
            margin-bottom: 0;
            text-align: center;
            width: 22vw;

            img {
              height: auto;
              width: 100%;
            }

            span {
              font-size: 1.2rem;
            }

            .react-pdf__Page {
              width: 8rem;

              .react-pdf__Page__canvas {
                height: 10rem !important;
              }
            }
          }
        }
      }

      .product-desktop-wrapper {
        display: none;
      }

      .product-mobile-wrapper {
        @include flexbox();
        @include justify-content(flex-end);

        .product-nav {
          position: fixed;
          left: 0;
          top: 17rem;
          width: max-content;
          z-index: 1;
          transform-origin: 20rem 20rem;
          @include transform(rotate(270deg));

          .button {
            background: $color-white;
            display: inline-block;

            &:hover {
              color: $color-link;
            }
          }
        }

        & > div {
          width: 90%;
        }

        h3 {
          text-align: center;
        }

        .block-info {
          display: block;
          margin: 2rem 0;
          text-align: center;
        }

        .two-columns {
          @include flex-direction(row);

          .product-img {
            @include flexbox();
            @include align-items(flex-end);
            width: 50%;
            &.hide {
              display: none;
            }

            img {
              margin-left: 0;
              width: 100%;
            }
          }

          .product-intro {
            margin-bottom: 0;
            width: 50%;
          }

          .allergens img {
            width: 5rem;
          }
        }

        .product-descr {
          font-size: 1.4rem;
        }

        .button-group {
          margin-bottom: 2rem;
          @include flex-direction(column);

          button,
          .button {
            margin: 0 0 1rem;
          }
        }
      }

      .block-info {
        .marketing-claims {
          font-size: 1rem;
          margin-right: 0;
          margin: 2rem 0;
          width: 100%;
        }

        .ingredients-icons {
          @include justify-content(center);
        }
      }
    }

    .packages img {
      max-width: 8rem;
    }

    .related-wrapper {
      margin-left: 1rem;
    }
  }

  .product-list {
    margin: 0;

    .product-item {
      width: 47%;

      span,
      .product-img-container span {
        display: block;
        font-size: 0.9rem;
      }

      .product-img {
        margin-left: -1rem;
        width: 7rem;
      }

      .block-info {
        overflow: visible;

        h3 {
          font-family: $font-sans-serif;
          font-size: 1.3rem;
        }
      }
    }
  }

  .tabs-list {
    display: none;
  }
}

@media screen and (max-width: 362px) {
  .product-list {
    .product-item {
      width: 70%;
    }
  }
}
