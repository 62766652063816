.modal-content-wrapper.error {

    &:before {
        border: 1px solid #c5726e;
    }

    h2 {
        color: #c5726e;
    }
}
