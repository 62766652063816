@import '../../variables';

.body-wrapper.page404 {
  text-align: center;
  @include align-items(center);

  button {
    margin: 1rem;
  }
}
