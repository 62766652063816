@import '../../variables';

.alert-body {
  background-color: #d70d14;
  color: $color-white;
  font-family: $font-sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  height: auto;
  left: 0;
  padding: 3.2rem 10%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  .button {
    border-color: $color-white;
    color: $color-white;
    margin-left: 6%;

    &:hover {
      background: $color-white;
      color: #d70d14;
    }
  }

  .alert-close {
    background-color: transparent;
    background-image: url("../../assets/img/icon-cross.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
    height: 2.5rem;
    padding: 0;
    position: absolute;
    right: 5%;
    top: 35%;
    width: 2.5rem;

    &:hover, &:focus {
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: 767px) {
  .alert-body {
    font-size: 1.6rem;
    padding: 4%;
    padding-top: 6%;
    text-align: center;
    @include flex-direction(column);
    // @include align-items(flex-start);

    span {
      margin-bottom: 1rem;
    }

    .button {
      margin-left: 0;
    }

    .alert-close {
      height: 2rem;
      right: 2%;
      top: 5%;
      width: 2rem;
    }
  }
}
