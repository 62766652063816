@import '../../variables';

.hero-wrapper.boom {
  background-image: url('../../assets/img/offers/boom-boom/top-image.jpg');
  padding: 2% 13%;
  text-align: left;
  @include align-items(flex-start);
  @include justify-content(center);

  h1,
  .descr {
    width: 65%;
  }

  .descr {
    text-align: left;
  }
}

.body-wrapper.boom {
  .benefits-wrapper {
    width: 80%;
    @include justify-content(space-between);
    @include align-items(flex-start);

    & > div {
      border: 2px solid $color-pampas;
      margin-bottom: 12vw;
      min-height: 25rem;
      padding: 0 2rem 6rem 2rem;
      position: relative;
      text-align: center;
      width: 30%;

      .descr {
        margin: 0;
        text-align: left;
        width: 100%;

        span {
          border: 0;
        }
      }

      img {
        height: auto;
        top: 20rem;
        left: 15%;
        position: absolute;
        width: 80%;
        z-index: 1;
      }
    }
  }

  .product-wrapper {
    @include flexbox;
    @include justify-content(center);
    @include flex-wrap(wrap);

    img {
      height: 100%;
      max-width: 30rem;
      width: 100%;
      object-fit: cover;
    }
  }

  table {
    font-size: 1.6rem;
    margin: 3rem 0;

    tr:nth-child(odd) td {
      background-color: $color-pampas;
    }

    td:last-child {
      font-weight: 700;
    }
  }

  .remark {
    bottom: 0;
    margin: 4rem 0;
    position: relative;
    right: 0;
  }
}

@media screen and (max-width: 1000px) {
  .hero-wrapper.boom {
    padding: 4%;
  }

  .body-wrapper.boom {
    .benefits-wrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.boom {
    h1,
    .descr {
      width: 75%;
    }
  }

  .body-wrapper.boom {
    .benefits-wrapper {
      width: 80%;

      & > div {
        width: 100%;

        img {
          top: 15rem;
          left: 25%;
          width: 60%;
        }
      }
    }

    .product-wrapper img {
      width: 20rem;
    }
  }
}
