/* Fonts */
$font-serif: 'DM Serif Display', serif;
$font-sans-serif: 'Open Sans', sans-serif;
$font-sans-serif-bold: 'Merriweather Sans', sans-serif;

/* Colors */
$color-primary: #062139;
$color-secondary: #cdd3d7;
$color-white: #ffffff;
$color-metal: #816e3a;
$color-soft: #f0e2b3;
$color-pampas: #e9e9e8;
$color-link: #de8826;
$color-red: #990000;
$color-grey: #afb7bd;

/* Mixins */
@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -o-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transform-style($property) {
  -webkit-transform-style: $property;
  -moz-transform-style: $property;
  -o-transform-style: $property;
  -ms-transform-style: $property;
  transform-style: $property;
}

@mixin transition {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@mixin perspective($property) {
  -webkit-perspective: $property;
  -moz-perspective: $property;
  -o-perspective: $property;
  -ms-perspective: $property;
  perspective: $property;
}

@mixin backface-visibility($property) {
  -webkit-backface-visibility: $property;
  -moz-backface-visibility: $property;
  -o-backface-visibility: $property;
  backface-visibility: $property;
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}
