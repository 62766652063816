@import '../../variables';

.hero-wrapper.home {
  background-image: url('../../assets/img/hero-home.jpg');
}

@media screen and (max-width: 767px) {
  .hero-wrapper.home {
    h1 {
      font-size: 2.5rem;
      @include order(2);
    }

    form {
      @include order(1);
    }
  }
}
