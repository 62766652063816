@import '../../variables';

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.4rem;
  width: 2.4rem;
  background-color: $color-white;
  border: 1px solid $color-primary;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 3.5rem;
  margin-bottom: 1rem;
  min-height: 2.4rem;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 1.6;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $color-primary;

      &:after {
        display: block;
      }
    }
  }

  .checkmark:after {
    left: 0.7rem;
    top: 0.3rem;
    width: 0.5rem;
    height: 1rem;
    border: solid $color-white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
