@import '../../variables';

.hero-wrapper.plusone {
  background-image: url('../../assets/img/hero-plusone-dark.jpg');
  position: relative;

  .descr {
    margin-top: 4rem;
    width: 70%;
  }

  .filters > div {
    margin: 2rem 0;
    @include justify-content(center);

    .select-wraper {
      margin: -.75rem 1rem 0;
    }
  }

  .select__menu {
      .select__group {
          .select__option {
              position: relative;
              padding: .8rem 1.2rem .8rem 3.2rem;

              &::before {
                  content: '';
                  display: block;
                  position: absolute;
                  height: 1.2rem;
                  width: 1.2rem;
                  top: 1.2rem;
                  left: 1.2rem;
                  border: 1px solid black;
              }

              &.select__option--is-selected {
                  background-color: transparent;
                  &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 1.5rem;
                        top: 1rem;
                        width: .5rem;
                        height: 1rem;
                        border: solid #000;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                  }
                  &::before {
                      background: #fff;
                  }
              }
          }
      }
  }
}

.body-wrapper.plusone {
  text-align: left;

  .loader {
      display: block;
      width: 100%;
      padding: 1rem 0;
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.plusone .descr {
    margin: auto;
  }
}
