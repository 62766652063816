@import '../../variables';

.body-wrapper.contact {
  @include flex-direction(column);

  .contact-form {
    margin-bottom: 4rem;
    width: 70%;

    .submit {
      margin-top: 5rem;
      @include flexbox();
      @include justify-content(flex-start);
    }
  }
}

@media screen and (max-width: 767px) {
  .body-wrapper.contact {
    .contact-form {
      width: 100%;

      .two-columns > div:last-child {
        margin-top: 2rem;
      }

      .submit {
        margin-top: 3rem;
        @include justify-content(center);
      }
    }
  }
}
