@import '../../variables';

.hero-wrapper.bottles {
  background-image: url('../../assets/img/hero-bottles.jpg');
  @include align-items(flex-start);
  @include justify-content(flex-start);

  h2,
  .descr {
    color: $color-primary;
    text-align: left;
    text-shadow: none;
    width: 50rem;
  }

  .descr {
    font-weight: normal;
    width: 40rem;
  }
}

.hero-wrapper.bottles2 {
  background-image: url('../../assets/img/hero-bottles2.jpg');

  h2 {
    padding: 0 20vw;
  }
}

.body-wrapper.bottles {
  .slick-slide img {
    padding: 1.5rem;
  }

  .bottle-wrapper {
    @include flexbox();
    @include justify-content(center);

    .bottle-item {
      margin: 1rem;
      position: relative;
      width: 29vw;

      .circle {
        background-color: $color-secondary;
        border-radius: 50%;
        height: 29vw;
        margin-bottom: 1rem;
        overflow: hidden;
      }

      &:nth-child(1) {
        img {
          margin-top: 11vw;
        }
      }

      &:nth-child(2) {
        img {
          margin-top: -3vw;
          width: 21vw;
        }
      }

      &:nth-child(3) {
        img {
          height: 30vw;
          margin-left: -32vw;
          width: auto;
        }
      }
    }
  }

  .benefits-wrapper {
    padding: 0 10%;
    @include align-items(flex-start);

    img {
      margin-top: -25rem;
      margin-right: 3rem;
      max-width: 14.3rem;
      width: 20%; // IE fix
    }

    & > div {
      width: 80%; // IE fix

      .descr {
        margin: 0;
        margin-bottom: 2rem;
        text-align: left;
        width: 100%;

        span {
          border: 0;
          display: inline-block;
          font-size: 7rem;
          line-height: 4rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .body-wrapper.bottles .benefits-wrapper {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.bottles {
    background-position: top left;

    h2,
    .descr {
      width: 75%;
    }

    .descr {
      font-weight: 700;
    }
  }

  .hero-wrapper.bottles2 {
    height: 20rem;

    h2 {
      padding: 0;
    }
  }

  .body-wrapper.bottles {
    .bottle-wrapper {
      display: block;

      .bottle-item {
        width: 20rem;

        .circle {
          height: 20rem;
        }

        &:nth-child(1) {
          img {
            margin-top: 8rem;
          }
        }

        &:nth-child(2) {
          img {
            margin-top: -2rem;
            width: 15rem;
          }
        }

        &:nth-child(3) {
          img {
            height: 20rem;
            margin-left: -20rem;
            width: auto;
          }
        }
      }
    }

    .benefits-wrapper {
      @include flex-direction(row);

      img {
        margin-top: 0;
        width: 8rem;
      }

      .descr span {
        display: block;
        line-height: 0.8;
      }
    }
  }
}
