@import '../../variables';

footer {
  background-color: $color-secondary;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  padding: 1.2% 4%;

  a {
    letter-spacing: 0.05rem;
    margin: 0 1.5rem;
  }

  .legal {
    margin-right: 1.5rem;
  }

  &.footer-offers {
    background-color: $color-white;
    padding: 4%;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    @include flex-wrap(wrap);

    & > div {
      @include flexbox();
      @include align-items(center);
    }

    .logo {
      img {
        width: 7rem;
      }

      &.sbr {
        margin-bottom: 2rem;
        width: 100%;

        img {
          width: auto;
        }
      }
    }
  }

  .trademark {
    font-size: 1.6rem;
    margin-left: 2rem;
    text-transform: initial;
    text-wrap: nowrap;
  }
}

@media screen and (max-width: 1200px) {
  footer {
    .trademark {
      display: block;
      margin: 1rem 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  footer {
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);
    @include justify-content(center);

    .footer-nav {
      @include flexbox();
      @include flex-direction(column);
    }

    a {
      margin: 1rem 0;
    }

    .legal {
      margin: 1rem 0;
    }

    &.footer-offers {
      & > div {
        @include flex-direction(column);
      }
    }
  }
}
