@import '../../variables';

.hero-wrapper.dipping {
  background-color: $color-primary;
  height: 40vw;
  padding: 2% 10%;
  position: relative;
  text-align: left;
  @include align-items(flex-start);
  @include justify-content(center);

  &::before {
    content: '';
    background-image: url('../../assets/img/offers/dipping-cups/top-image.png');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }

  .logo {
    margin-bottom: 4vw;
    width: 25vw;
    z-index: 1;
  }

  h1 {
    font-family: $font-sans-serif-bold;
    font-size: 6vw;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    width: 60%;
    z-index: 1;
  }
}

.body-wrapper.dipping {
  &.background {
    background-color: #000000;
    color: $color-white;
    height: 25rem;

    &::before {
      background-image: url('../../assets/img/offers/dipping-cups/promo.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      height: 100%;
      opacity: 0.3;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
    }

    h2 {
      z-index: 1;
    }
  }

  h2 {
    font-size: 3.5rem;
    font-family: $font-sans-serif-bold;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-transform: uppercase;

    &.cross-line {
      margin: 5rem 0;

      &::before,
      &::after {
        content: '';
        color: $color-pampas;
        display: inline-block;
        width: 40%;
        margin: 0 1em 0 -55%;
        vertical-align: middle;
        border-bottom: 1px solid;
      }

      &::after {
        margin: 0 -55% 0 1em;
      }
    }
  }

  .benefits-wrapper {
    width: 80%;
    @include justify-content(space-between);
    @include align-items(flex-start);

    & > div {
      border: 2px solid $color-pampas;
      margin-bottom: 12vw;
      min-height: 30rem;
      padding: 2rem;
      position: relative;
      text-align: center;
      width: 30%;

      .descr {
        margin: 0;
        text-align: left;
        width: 100%;

        span {
          border: 0;
          font-size: 2.8rem;
          line-height: 1;
        }

        p {
          margin-bottom: 1rem;
        }

        a {
          font-size: 1.4rem;
          font-weight: 700;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      img {
        height: auto;
        bottom: -10rem;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
      }
    }
  }

  .flavor-item {
    margin: 3rem 0;

    img {
      height: 15rem;
      width: auto;
    }
  }
}

@media screen and (max-width: 1100px) {
  .body-wrapper.dipping {
    .benefits-wrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .hero-wrapper.dipping {
    padding: 4%;

    &::before {
      background-size: cover;
      opacity: 0.3;
    }

    .logo {
      width: 25rem;
    }

    h1 {
      font-size: 6rem;
      width: 60%;
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.dipping {
    height: auto;
    min-height: 25rem;
    @include align-items(center);

    h1 {
      font-size: 4rem;
      text-align: center;
      width: 100%;
    }
  }

  .body-wrapper.dipping {
    h2.cross-line {
      &::before,
      &::after {
        content: none;
      }
    }

    .benefits-wrapper {
      & > div {
        width: 100%;

        img {
          top: 18rem;
          width: auto;
        }
      }
    }
  }
}
