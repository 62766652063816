@import '../../../variables';

.filters {
  font-family: $font-serif;
  font-size: 3rem;
  width: 100%;
  @include flexbox();
  @include justify-content(center);

  & > div {
    font-size: 3rem;
    width: 100%;
    @include flexbox();
    @include justify-content(center);

    .select-big {
      margin: 1rem 0.5rem;
      width: 18%;
      @include flexbox();
      @include align-items(center);
    }
  }

  .select__single-value {
    overflow: visible;
  }

  .select__value-container--has-value {
    font-family: $font-sans-serif;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .filters > div {
    display: block;

    .select-big {
      width: 97%;
    }
  }
}
