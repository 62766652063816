@import '../../variables';

.body-wrapper.request {
  @include flex-direction(column);

  .request-form {
    margin-bottom: 4rem;
    width: 70%;
  }

  .is-invalid {
    .select {
      border-color: rgb(153, 0, 0);
    }

    p.error {
      display: block;
    }
  }

  p.error {
    display: none;
    margin: 0;
    padding-top: .5rem;
    font-size: 85%;
    color: rgb(153, 0, 0);
  }
}

@media screen and (max-width: 767px) {
  .body-wrapper.request {
    .request-form {
      width: 100%;

      .submit {
        margin-top: 3rem;
        @include justify-content(center);
      }
    }
  }
}
