@import '../../variables';

.hero-wrapper.metropolitan {
  background-image: url('../../assets/img/hero-metropolitan.jpg');
  @include justify-content(center);
}

.hero-wrapper.metropolitan2 {
  background-image: url('../../assets/img/metropolitan-flavor.jpg');
  height: 40rem;
  @include justify-content(center);

  h2,
  .descr {
    color: $color-primary;
  }

  .descr {
    font-weight: normal;
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.metropolitan2 {
    height: auto;

    .descr {
      font-weight: 700;
      width: 90%;
    }
  }
}
