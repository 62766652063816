@import '../../variables';

.hero-wrapper.covid {
  background-image: url('../../assets/img/hero-covid.jpg');
  background-position: bottom center;
  height: 64rem;
  margin-top: -9rem;
  image-rendering: -webkit-optimize-contrast;
  @include justify-content(flex-end);

  h2 {
    margin-bottom: 0;
  }
}

.hero-wrapper.covid2 {
  background-image: url('../../assets/img/hero-cups.jpg');
  image-rendering: -webkit-optimize-contrast;
  height: 45rem;

  &::before {
    content: "";
    background-color: rgba(0,0,0,0.25);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  h2,
  .button-group {
    z-index: 1;
  }

  h2 {
    margin-bottom: 10rem;
    max-width: 70%;
  }
}

.body-wrapper.covid {
  h2 {
    max-width: 70%;
  }

  .button-group {
    @include justify-content(center);
  }
}

@media screen and (max-width: 1000px) {
  .hero-wrapper.covid {
    height: 40rem;
    margin-top: -7rem;

    &::before {
      content: "";
      background-color: rgba(0,0,0,0.35);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }

    h1,
    h2 {
      z-index: 1;
    }
  }

  .hero-wrapper.covid2 {
    height: auto;
    min-height: 25rem;
    @include justify-content(center);

    h2 {
      margin-bottom: 2rem;
      max-width: none;
    }
  }

  .body-wrapper.covid {
    h2 {
      max-width: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.covid {
    height: auto;
    margin-top: -7rem;
    min-height: 25rem;
    padding: 4%;
  }
}
