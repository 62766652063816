@import '../../variables';

.hero-wrapper.essentials {
  background-image: url('../../assets/img/hero-essentials.jpg');
  padding-bottom: 6%;

  .descr {
    margin-bottom: 10rem;
  }
}

.body-wrapper.essentials {
  .block .block-txt {
    padding: 1rem;
  }
}

@media screen and (max-width: 1110px) {
  .body-wrapper.essentials {
    .feature-wrapper.single .descr br {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.essentials {
    .descr {
      margin-bottom: 2rem;
    }
  }
}
