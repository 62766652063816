@import '../../variables';

.hero-wrapper.tacos {
  background-image: url('../../assets/img/hero-tacos.jpg');
}

.body-wrapper.tacos {
  .feature-wrapper {
    img {
      height: 100%;
      margin-left: -12vw;
      margin-top: -5vw;
      width: 60%;
    }

    .feature-block {
      margin: 0 0 10% 0;
      text-align: left;
      width: 100%;
      @include flexbox();
      @include align-items(center);

      .descr {
        margin: 0;
        margin-left: 4%;
      }
    }
  }

  .benefits-wrapper {
    img {
      width: 14%;
    }

    .descr {
      span {
        border: 0;
        border-top: 2px solid $color-primary;
        margin: 0;
        margin-top: 1rem;
      }
    }

    .img-block {
      img {
        width: 100%;
      }

      span {
        width: 75%;
      }
    }
  }

  .flavor-item img {
    width: 20rem;
  }
}

@media screen and (max-width: 1000px) {
  .body-wrapper.tacos {
    h2 br {
      display: none;
    }

    .benefits-wrapper .img-block span {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-wrapper.tacos {
    height: 20rem;
  }

  .body-wrapper.tacos {
    .descr {
      font-size: 1.6rem;
    }

    .feature-wrapper {
      img {
        display: none;
      }

      .feature-block {
        height: auto;
        margin: 0;
        margin-bottom: 5rem;

        .descr {
          text-align: left;
        }
      }
    }

    .benefits-wrapper {
      img {
        width: 50%;
      }

      &:first-child {
        .descr {
          order: 2;

          &:last-child {
            order: 4;
          }
        }

        img {
          margin: 5rem 0 2rem 0;
          order: 3;
        }

        .img-block {
          margin: 2rem 0;
          order: 1;
        }
      }

      &:last-child {
        .img-block {
          margin-top: 0;
          margin-bottom: 2rem;
          order: 3;
        }

        .descr {
          order: 4;

          &:first-child {
            order: 2;
          }
        }

        img {
          margin: 5rem 0 2rem 0;
          order: 1;
        }
      }
    }
  }
}
